import { useController, UseControllerProps } from 'react-hook-form';
import Autocomplete from 'components/inputs/Autocomplete';
import { IAutocompleteProps, IAutocompleteValue } from 'components/inputs/Autocomplete/types';

interface AutocompleteControlProps<
  Value extends Record<string, any> | string = IAutocompleteValue,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  TOption extends Record<string, any> | string = Value,
> extends Omit<IAutocompleteProps<Value, Multiple, DisableClearable, FreeSolo, TOption>, 'onChange' | 'value'> {
  name: string;
  rules?: UseControllerProps['rules'];
}
const AutocompleteControl = <
  Value extends Record<string, any> | string = IAutocompleteValue,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  TOption extends Record<string, any> | string = Value,
>({
    name,
    rules,
    ...props
  }: AutocompleteControlProps<Value, Multiple, DisableClearable, FreeSolo, TOption>) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, rules });
  return (
    <Autocomplete<Value, Multiple, DisableClearable, FreeSolo, TOption>
      value={field.value}
      error={error}
      onChange={field.onChange}
      {...props}
    />
  );
};

export default AutocompleteControl;
