import { Grade, Question_Type } from '@generated/graphql';

const GRADE_OPTIONS = [
  {
    id: Grade.Junior,
    displayName: 'Junior',
  },
  {
    id: Grade.Middle,
    displayName: 'Middle',
  },
  {
    id: Grade.Senior,
    displayName: 'Senior',
  },
];

const QUESTION_TYPES = [
  {
    label: 'Практический',
    value: Question_Type.Practical,
  },
  {
    label: 'Теоретический',
    value: Question_Type.Theoretical,
  },
];

export {
  GRADE_OPTIONS, QUESTION_TYPES,
};
