import { ToastContext, ToastTypeEnum } from 'context/toastContext';
import { useCreateOrUpdateCompanyCandidateMutation } from '@generated/graphql';
import { useContext } from 'react';
import { CompanyContext } from 'context/companyContext';
import { InfoInputs, IUseTrialSubmitParams } from '../types';
import useUpdateTrial from './useUpdateTrial';

const useUpdateCandidate = ({ setIsEditing }: IUseTrialSubmitParams) => {
  const { addToast } = useContext(ToastContext);
  const { currentCompanyId } = useContext(CompanyContext);

  const toggleToastOnError = (text?: string) => addToast({ type: ToastTypeEnum.ERROR, text });

  const [updateCompanyCandidate,
    { loading: updateCompanyCandidateLoading }] = useCreateOrUpdateCompanyCandidateMutation();

  const { updateTrialLoading, onUpdateTrial } = useUpdateTrial();

  const onUpdateCompanyCandidate = (formData: InfoInputs) => updateCompanyCandidate({
    variables: {
      input: {
        id: formData.candidate?.id!,
        email: formData.candidate?.email,
        companyId: currentCompanyId!,
        fullName: formData.candidate?.fullName?.fullName!,
        grade: formData.candidate?.grade?.id,
        telegramUsername:
          formData.candidate?.telegramUsername!.candidateUsername?.telegramUsername?.replaceAll('@', '')!,
      },
    },
  })
    .then((candidateRes) => {
      const candidateId = candidateRes.data?.createOrUpdateCompanyCandidate.id;
      if (candidateId) {
        onUpdateTrial({ formData, candidateId, setIsEditing });
      } else {
        toggleToastOnError('Ошибка сохранения кандидата');
      }
    })
    .catch(() => toggleToastOnError('Ошибка сохранения кандидата'));

  return { onUpdateCompanyCandidate, updateCompanyCandidateLoading, updateTrialLoading };
};

export default useUpdateCandidate;
