import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import Form from 'components/form/Form';
import { Card, Stack } from '@mui/material';
import TextFieldControl from 'components/form/TextFieldControl';
import {
  CompetenceQuery, useCompetenceSpecializationsQuery,
  useCreateOrUpdateCompetenceMutation, useDeleteCompetenceMutation,
} from '@generated/graphql';
import DatePickerControl from 'components/form/DatePickerControl';
import SaveEditButtons from 'components/buttons/SaveEditButtons';
import AutocompleteControl from 'components/form/AutocompleteControl';
import useIsCreate from 'hooks/useIsCreate';
import { ToastContext, ToastTypeEnum } from 'context/toastContext';
import { useNavigate } from 'react-router-dom';
import ROUTES from 'constants/routes';
import { CompanyContext } from 'context/companyContext';
import { ConfirmModalContext } from 'context/confirmModalContext';
import VALIDATION from 'helpers/validation';
import { cleanSpaceInString } from 'helpers/validation/createValidation';

type CompetenceInfoInputs = {
  title: string;
  createdAt: string;
  updatedAt: string;
  grades: string;
  programingLanguage: string,
};

const Info = ({ competence }: { competence: CompetenceQuery['competence'] | null | undefined }) => {
  const { addToast } = useContext(ToastContext);
  const { currentCompanyId } = useContext(CompanyContext);
  const { handleOpen } = useContext(ConfirmModalContext);
  const navigate = useNavigate();
  const isCreate = useIsCreate();
  const [isEditing, setIsEditing] = useState(isCreate);

  const form = useForm<CompetenceInfoInputs>({
    defaultValues: {
      title: competence?.title || '',
      updatedAt: competence?.updatedAt,
      createdAt: competence?.createdAt,
      programingLanguage: competence?.programingLanguage,
      grades: competence?.grades?.join(', ') || '',
    },
  });
  const { handleSubmit } = form;

  const { data, loading } = useCompetenceSpecializationsQuery();

  const [updateCompetence, { loading: updateLoading }] = useCreateOrUpdateCompetenceMutation();
  const onSubmit = (formData: CompetenceInfoInputs) => {
    if (!currentCompanyId) {
      addToast({ type: ToastTypeEnum.ERROR });
      return;
    }
    updateCompetence({
      variables: {
        input: {
          companyId: currentCompanyId,
          id: competence?.id,
          title: cleanSpaceInString(formData.title),
          programingLanguage: formData.programingLanguage,
        },
      },
    }).then((res) => {
      if (isCreate) navigate(`/${ROUTES.COMPETENCIES}/view/${res.data?.createOrUpdateCompetence.id}`);
      setIsEditing(false);
      addToast({ type: ToastTypeEnum.SUCCESS });
    }).catch(() => addToast({ type: ToastTypeEnum.ERROR }));
  };

  const [deleteCompetence] = useDeleteCompetenceMutation();

  const handleDeleteCompetence = async () => {
    await deleteCompetence({
      variables: {
        competenceId: competence?.id!,
      },
    }).then(() => {
      addToast({ type: ToastTypeEnum.SUCCESS, text: 'Компетенция успешно удалена' });
      navigate(`/${ROUTES.COMPETENCIES}`);
    }).catch(() => {
      addToast({ type: ToastTypeEnum.ERROR });
    });
  };

  const handleOpenConfirmDeleteModal = () => {
    handleOpen({
      title: 'Вы уверены, что хотите удалить компетенцию?',
      actionButtonText: 'Удалить',
      handleClick: handleDeleteCompetence,
    });
  };

  return (
    <Card>
      <Form form={form}>
        <Stack spacing={2.5}>
          <TextFieldControl
            name="title"
            label="Название"
            disabled={!isEditing}
            rules={{ ...VALIDATION.competencyMapTitle, required: true }}
          />
          <AutocompleteControl
            options={data?.competenceSpecializations || []}
            loading={loading}
            name="programingLanguage"
            label="Навык"
            disabled={!isEditing}
            rules={{ required: true }}
          />
          <TextFieldControl name="grades" label="Грейд" disabled />
          <DatePickerControl name="createdAt" label="Дата создания" disabled />
          <DatePickerControl name="updatedAt" label="Дата изменения" disabled />
          <SaveEditButtons
            isEdited={isEditing}
            onSubmit={handleSubmit(onSubmit)}
            setIsEdited={setIsEditing}
            loading={updateLoading}
            deleteText="Удалить компетенцию"
            onDelete={handleOpenConfirmDeleteModal}
          />
        </Stack>
      </Form>
    </Card>
  );
};

export default Info;
