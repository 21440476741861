import { useParams, useSearchParams } from 'react-router-dom';
import { useTopicQuery } from '@generated/graphql';
import useIsCreate from 'hooks/useIsCreate';
import CircularLoading from 'components/CircularLoading';
import { Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import React, { useContext, useMemo, useState } from 'react';
import Info from 'pages/Topic/view/components/Info';
import GenerateQuestionsModal from 'pages/Topic/view/components/GenerateQuestionsModal';
import Questions from 'pages/Topic/view/components/QuestionItem';
import ViewGridLayout from 'layout/ViewGridLayout';
import useSetTabTitle from 'hooks/useSetTabTitle';
import UpdateQuestionModal from 'pages/Topic/view/components/UpdateQuestionModal';
import BackPageButton from 'components/buttons/BackPageButton';
import qs from 'qs';
import ROUTES from 'constants/routes';
import { CompanyContext } from 'context/companyContext';
import ButtonWithPermissions, { generateUnavailableText } from 'components/buttons/ButtonWithPermissions';

const TopicView = () => {
  const { isCreateAvailable, currentCompany } = useContext(CompanyContext);

  const generateQuestionsUnavailableText = generateUnavailableText(
    isCreateAvailable,
    currentCompany,
    'генерации вопросов',
  );

  const isCreate = useIsCreate();
  const [searchParams] = useSearchParams();
  const params = useMemo(() => qs.parse(searchParams.toString()), [searchParams]);
  const {
    competenceId,
    competenceTitle,
  } = params as unknown as {
    competenceId?: string;
    competenceTitle?: string;
  };
  const { topicId } = useParams();
  const topicQuery = useTopicQuery({
    variables: {
      topicId: topicId!,
    },
    skip: !topicId,
  });

  const { data, loading, refetch } = topicQuery;

  const topic = data?.topic;

  const [openGenerateQuestionsModal, setOpenGenerateQuestionsModal] = useState<boolean>(false);
  const [openCreateQuestionModal, setOpenCreateQuestionModal] = useState<boolean>(false);
  const title = `Топик ${topic?.title ? ` «${topic.title}»` : ''}`;
  useSetTabTitle(title, topic?.title);

  const competence = topic?.competence || { id: competenceId, title: competenceTitle };

  if (loading) return <CircularLoading />;

  return (
    <>
      <ViewGridLayout
        Header={(
          <Stack sx={{ width: '100%' }} spacing={2} alignItems="flex-start">
            <BackPageButton
              text={`Компетенция ${competence.title}`}
              link={`/${ROUTES.COMPETENCIES}/view/${competence.id}`}
            />
            <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
              <Typography variant="h28">
                {title}
              </Typography>
              {!isCreate && (
                <Stack direction="row" spacing={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      backgroundColor: 'white',
                      color: 'text.primary',
                      '&:hover': {
                        color: 'white',
                      },
                    }}
                    onClick={() => setOpenCreateQuestionModal(true)}
                  >
                    Создать вопрос
                  </Button>
                  <ButtonWithPermissions
                    buttonText="Сгенерировать вопросы"
                    onClick={() => setOpenGenerateQuestionsModal(true)}
                    tooltipText={generateQuestionsUnavailableText}
                  />
                </Stack>
              )}
            </Stack>
          </Stack>
          )}
        LeftColumn={<Questions topicQuery={topicQuery} />}
        RightColumn={<Info topic={topic} />}
      />
      {
        openGenerateQuestionsModal && (
        <GenerateQuestionsModal
          open={openGenerateQuestionsModal}
          onClose={() => setOpenGenerateQuestionsModal(false)}
          competence={topic?.competence!}
          topic={{ title: topic?.title!, id: topic?.id! }}
        />
        )
      }
      {
        openCreateQuestionModal && (
          <UpdateQuestionModal
            isOpen={openCreateQuestionModal}
            onClose={() => setOpenCreateQuestionModal(false)}
            isCreate
            topic={topic}
            refetchTopic={refetch}
          />
        )
      }
    </>
  );
};

export default TopicView;
