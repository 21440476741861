import { Question_Lang } from '@generated/graphql';

export const QUESTION_LANGUAGE_TITLE = {
  [Question_Lang.English]: 'Английский',
  [Question_Lang.Kazakh]: 'Казахский',
  [Question_Lang.Russian]: 'Русский',
};

export const QUESTION_LANGUAGE_OPTIONS = [
  {
    value: Question_Lang.English,
    label: QUESTION_LANGUAGE_TITLE[Question_Lang.English],
  },
  {
    value: Question_Lang.Kazakh,
    label: QUESTION_LANGUAGE_TITLE[Question_Lang.Kazakh],
  },
  {
    value: Question_Lang.Russian,
    label: QUESTION_LANGUAGE_TITLE[Question_Lang.Russian],
  },
];
