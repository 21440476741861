import { useNavigate, useParams } from 'react-router-dom';
import { useCompetenceQuery, useTopicsQuery } from '@generated/graphql';
import CircularLoading from 'components/CircularLoading';
import {
  Grid, Stack, Typography,
} from '@mui/material';
import React, { useContext, useEffect } from 'react';
import Filters from 'layout/ListLayout/components/Filters';
import { useForm } from 'react-hook-form';
import TopicCard, { TopicCardProps } from 'pages/Competencies/view/components/TopicCard';
import Info from 'pages/Competencies/view/components/Info';
import { generateFilterQuery } from 'components/generators/filtersGenerator';
import useList from 'hooks/useList';
import _ from 'lodash';
import EmptyList from 'components/EmptyList';
import useIsCreate from 'hooks/useIsCreate';
import useSetTabTitle from 'hooks/useSetTabTitle';
import ROUTES from 'constants/routes';
import { CompanyContext } from 'context/companyContext';
import ButtonWithPermissions, { generateUnavailableText } from 'components/buttons/ButtonWithPermissions';
import CompetencyMapsList from './components/CompetencyMapsList';
import filterFields from './filterFields';

const CompetenceView = () => {
  const { isCreateAvailable, currentCompany } = useContext(CompanyContext);
  const createTopicUnavailableText = generateUnavailableText(isCreateAvailable, currentCompany, 'создания топиков');

  const navigate = useNavigate();
  const isCreate = useIsCreate();
  const { competenceId } = useParams();
  const { data, loading } = useCompetenceQuery({
    variables: {
      competenceId: competenceId!,
    },
    skip: !competenceId,
  });

  const { data: topicsData, loading: topicsLoading, refetch } = useTopicsQuery({
    variables: {
      page: 0,
      limit: 1000,
      filter: {
        competenceId: competenceId!,
      },
    },
    skip: !competenceId,
  });

  const competence = data?.competence;
  const form = useForm();

  const { params } = useList();

  useEffect(() => {
    if (refetch && competenceId) {
      refetch({
        filter: _.merge({
          competenceId: competenceId!,
        }, generateFilterQuery(filterFields, params)),
      });
    }
  }, [params]);

  const topics = topicsData?.topics.data;

  const title = `Компетенция ${competence?.title ? ` «${competence.title}»` : ''}`;
  useSetTabTitle(title, competence?.title);

  const onCreateTopic = () => {
    navigate(`/${ROUTES.TOPIC}/create?competenceId=${competenceId}&competenceTitle=${competence?.title}`);
  };

  if (loading || topicsLoading) return <CircularLoading />;

  return (
    <>
      <Stack direction="row" justifyContent="space-between" mb={2.5}>
        <Typography variant="h28">
          {title}
        </Typography>
        {!isCreate && (
          <ButtonWithPermissions
            buttonText="Создать топик"
            onClick={onCreateTopic}
            tooltipText={createTopicUnavailableText}
          />
        )}
      </Stack>
      <Filters form={form} filters={filterFields} />
      <Grid container columnSpacing={2.5}>
        <Grid container item xs={12} md={9} columnSpacing={2.5} rowSpacing={2.5} sx={{ height: 'fit-content' }}>
          {topics?.length
            ? topicsData?.topics.data?.map((topic) => (
              <Grid item xs={12} md={6} lg={3} key={`topic-${topic.id}`}>
                <TopicCard topic={topic as TopicCardProps} />
              </Grid>
            ))
            : <EmptyList emptyPlaceholderText="Пока нет топиков" />}
        </Grid>
        <Grid item xs={12} md={3}>
          <Stack spacing={2.5}>
            <Info competence={competence} />
            {!isCreate && <CompetencyMapsList competencyMaps={competence?.competenceMaps} />}
          </Stack>

        </Grid>
      </Grid>
    </>
  );
};
export default CompetenceView;
