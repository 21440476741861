import React, { useCallback, useContext } from 'react';
import { debounce } from 'lodash';
import { UseFormReturn } from 'react-hook-form';
import { AutocompleteInputChangeReason, FilterOptionsState } from '@mui/material';
import AutocompleteControl from 'components/form/AutocompleteControl';
import TextFieldControl from 'components/form/TextFieldControl';
import { GRADE_OPTIONS } from 'constants/global';
import { useCompanyCandidatesQuery } from '@generated/graphql';
import { CompanyContext } from 'context/companyContext';
import {
  InfoInputs,
  CandidateDataOption,
  ICandidateFullNameOption,
  ICandidateUsernameOption,
} from '../types';

type CandidateInfoProps = {
  form: UseFormReturn<InfoInputs>;
  isEditing: boolean;
};

const CandidateInfo: React.FC<CandidateInfoProps> = ({ form, isEditing }) => {
  const { currentCompanyId } = useContext(CompanyContext);
  const {
    setValue, getValues, reset,
  } = form;
  const { loading: candidatesLoading, data: candidatesData, refetch } = useCompanyCandidatesQuery({
    variables: { input: {}, page: 0, limit: 15 },
    notifyOnNetworkStatusChange: true,
  });

  const debounceQuery = useCallback(
    debounce((inputValue, fieldName) => {
      refetch({ input: { [fieldName]: inputValue }, limit: 15, page: 0 });
    }, 500),
    [],
  );

  const resetCandidateFields = () => {
    reset({
      ...getValues(),
      candidate: {
        email: '',
        phoneNumber: '',
        grade: null,
        telegramUsername: { id: '', candidateUsername: { telegramUsername: '' } },
        fullName: { id: '', fullName: '' },
        id: null,
      },
    });
  };

  const onAutocompleteInputChange = (
    inputValue: string,
    reason: AutocompleteInputChangeReason,
    filterName: 'fullName' | 'telegramUsername',
  ) => {
    if (reason === 'clear') {
      resetCandidateFields();
    } else if (reason === 'input') {
      const searchValue = inputValue.replace('@', '');
      debounceQuery(searchValue, filterName);
    }
  };

  const setCandidateData = (data: CandidateDataOption) => {
    const companyCandidate = data.candidateUsername?.companyCandidate?.find((c) => c.companyId === currentCompanyId);
    const candidate = data.candidateUsername?.candidate;
    const values = [
      { name: 'candidate.grade', value: GRADE_OPTIONS?.find((grade) => companyCandidate?.grade === grade.id) },
      { name: 'candidate.email', value: companyCandidate?.email },
      { name: 'candidate.phoneNumber', value: candidate?.phoneNumber },
      { name: 'candidate.id', value: data?.id },
    ];
    values.forEach(({ name, value }) => setValue(name as keyof InfoInputs, value as InfoInputs[keyof InfoInputs]));
  };

  const handleFullNameChange = (data: ICandidateFullNameOption | CandidateDataOption | null | string) => {
    if (!data || typeof data === 'string' || !('candidateUsername' in data)) return data;
    setCandidateData(data);
    const telegramUsername = data.candidateUsername?.telegramUsername;
    setValue('candidate.telegramUsername', {
      id: data.id,
      candidateUsername: { telegramUsername: telegramUsername ? `@${telegramUsername}` : telegramUsername || '' },
    });
    return { id: data.id, fullName: data.fullName };
  };

  const handleUsernameChange = (data: ICandidateUsernameOption | CandidateDataOption | null | string) => {
    if (!data || typeof data === 'string') return data;

    if ('fullName' in data) {
      setCandidateData(data);
      setValue('candidate.fullName', { id: data.id, fullName: data.fullName });
    }

    const telegramUsername = data.candidateUsername?.telegramUsername;
    const firstSymbol = telegramUsername?.[0];
    const telegramUsernameValue = firstSymbol === '@' ? telegramUsername : `@${telegramUsername}`;

    return { id: data.id, candidateUsername: { telegramUsername: telegramUsernameValue } };
  };

  const onChangeUsernameTextField = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newEvent = event as React.ChangeEvent<HTMLInputElement>;
    const inputValue = event.target.value;
    newEvent.target.value = inputValue?.length > 1 || inputValue === '@'
      ? inputValue.replace(/(?!^)@/g, '')
      : `@${inputValue}`;
    return newEvent;
  };

  const filterUsernameOptions = (params: FilterOptionsState<ICandidateUsernameOption>) => {
    const newParams = params;
    newParams.inputValue = params.inputValue.replace('@', '');
    return newParams;
  };

  return (
    <>
      <AutocompleteControl<ICandidateFullNameOption, false, false, true, CandidateDataOption>
        name="candidate.fullName"
        label="ФИО"
        rules={{ required: true }}
        disabled={!isEditing}
        options={candidatesData?.companyCandidates.data || []}
        loading={candidatesLoading}
        onInputChange={(event, value, reason) => onAutocompleteInputChange(value, reason, 'fullName')}
        optionName="fullName"
        freeSolo
        formatDataBeforeOnChange={handleFullNameChange}
      />
      <AutocompleteControl<ICandidateUsernameOption, false, false, true, CandidateDataOption>
        name="candidate.telegramUsername"
        label="Профиль Telegram"
        rules={{ required: true }}
        disabled={!isEditing}
        options={candidatesData?.companyCandidates.data || []}
        loading={candidatesLoading}
        // @ts-ignore
        optionName="candidateUsername.telegramUsername"
        freeSolo
        onInputChange={(event, value, reason) => onAutocompleteInputChange(value, reason, 'telegramUsername')}
        onChangeTextField={onChangeUsernameTextField}
        filterOptionsParams={filterUsernameOptions}
        formatDataBeforeOnChange={handleUsernameChange}
      />
      <TextFieldControl
        name="candidate.phoneNumber"
        label="Номер телефона"
        disabled
        maskProps={{ mask: '+{7} (000) 000-00-00' }}
      />
      <TextFieldControl name="candidate.email" label="Почта" disabled={!isEditing} />
      <AutocompleteControl
        options={GRADE_OPTIONS}
        name="candidate.grade"
        label="Грейд кандидата"
        disabled={!isEditing}
      />
    </>
  );
};

export default CandidateInfo;
