import { GRADE_OPTIONS } from 'constants/global';
import { formatISODate } from 'helpers/date';
import { Trial_Status, TrialInfoQuery } from '@generated/graphql';
import { useMemo } from 'react';
import qs from 'qs';
import { useSearchParams } from 'react-router-dom';
import { CompetencyMapOptionList } from 'pages/Testing/view/components/InfoTab/types';

const useInitTrialForm = (trial: TrialInfoQuery['trial'], competencyMaps: CompetencyMapOptionList) => {
  const [searchParams] = useSearchParams();
  const params = useMemo(() => qs.parse(searchParams.toString()), [searchParams]);
  const { mapId } = params as unknown as { mapId?: string; };

  const competencyMap = mapId
    ? competencyMaps?.find((map) => map.id === mapId)
    : trial?.competencyMap;

  const companyCandidate = trial?.companyCandidate;
  const candidateUsername = companyCandidate?.candidateUsername;
  const comment = trial?.comment;
  const trialStatusHistory = trial?.trialStatusHistory;
  const createdAt = trial?.createdAt;
  const completedAt = trial?.completedAt;

  const fullName = companyCandidate
    ? { id: companyCandidate.id || '', fullName: companyCandidate.fullName || '' }
    : null;

  const getTelegramUserName = () => {
    const telegramUsername = candidateUsername?.telegramUsername;
    if (telegramUsername) {
      return `@${telegramUsername}`;
    }
    return '';
  };

  const telegramUsername = companyCandidate
    ? { id: companyCandidate.id || '', candidateUsername: { telegramUsername: getTelegramUserName() } }
    : null;

  const startedAt = trialStatusHistory
    ?.find((history) => history.newStatus === Trial_Status.Started)?.createdAt;

  return {
    id: trial?.id || '',
    candidate: {
      id: companyCandidate?.id || '',
      fullName,
      grade: GRADE_OPTIONS?.find((grade) => companyCandidate?.grade === grade.id) || null,
      telegramUsername,
      phoneNumber: candidateUsername?.candidate?.phoneNumber?.replace('+', '') || '',
      email: companyCandidate?.email || '',
    },
    comment,
    createdAt: createdAt ? formatISODate(createdAt, 'dd.MM.yyyy HH:mm') : '',
    completedAt: completedAt ? formatISODate(completedAt, 'dd.MM.yyyy HH:mm') : '',
    startedAt: startedAt ? formatISODate(startedAt, 'dd.MM.yyyy HH:mm') : '',
    competencyMap: competencyMap ? {
      id: competencyMap?.id || '',
      title: competencyMap?.title || '',
      comment: competencyMap?.comment || '',
      grades: competencyMap?.grades?.join(', ') || '',
    } : null,
    link: trial?.id ? `https://t.me/${process.env.REACT_APP_BOT_URL}?start=${trial.id}` : '',
  };
};

export default useInitTrialForm;
