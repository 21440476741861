import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** Date custom scalar type */
  Date: { input: any; output: any; }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any; }
};

export type AuthTokensDto = {
  __typename?: 'AuthTokensDto';
  accessToken: Scalars['String']['output'];
  refreshToken: Scalars['String']['output'];
};

export enum Company_Status {
  Approved = 'APPROVED',
  Blocked = 'BLOCKED',
  Created = 'CREATED',
  NotApproved = 'NOT_APPROVED',
  PartlyApproved = 'PARTLY_APPROVED'
}

export type Candidate = {
  __typename?: 'Candidate';
  /** Username кандидата в ТГ */
  candidateUsername: CandidateUsername;
  /** ID Username кандидата в ТГ */
  candidateUsernameId: Scalars['ID']['output'];
  createdAt: Scalars['Date']['output'];
  /** ФИО кандидата из ТГ */
  fullName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Номер телефона кандидата */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
};

export type CandidateUsername = {
  __typename?: 'CandidateUsername';
  /** Кандидат */
  candidate?: Maybe<Candidate>;
  /** Связь кандидата с компанией */
  companyCandidate?: Maybe<Array<CompanyCandidate>>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  /** Профиль телеграм кандидата */
  telegramUsername: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
};

export type Company = {
  __typename?: 'Company';
  /** Дата последних изменений */
  actualityDate?: Maybe<Scalars['Float']['output']>;
  /** Компетенции */
  companyCompetences?: Maybe<Array<Competence>>;
  /** История изменения статуса */
  companyStatusHistory?: Maybe<Array<CompanyStatusHistory>>;
  /** Пользователи компании */
  companyUsers?: Maybe<Array<CompanyUser>>;
  /** Карты компетенций компании */
  competencyMaps?: Maybe<Array<CompetencyMap>>;
  createdAt: Scalars['Date']['output'];
  /** Позиция текущего пользователя */
  currentUserPosition?: Maybe<Scalars['String']['output']>;
  /** Полное наименование */
  fullName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Идентификатор компании */
  identification: Scalars['String']['output'];
  /** ИНН */
  inn: Scalars['String']['output'];
  /** Флаг суперкомпании */
  isSupercompany: Scalars['Boolean']['output'];
  /** КПП */
  kpp: Scalars['String']['output'];
  /** Дата ликвидации */
  liquidationDate?: Maybe<Scalars['Float']['output']>;
  /** ФИО руководителя */
  managerFio: Scalars['String']['output'];
  /** Должность руководителя */
  managerPosition: Scalars['String']['output'];
  /** ОГРН */
  ogrn: Scalars['String']['output'];
  /** Дата выдачи ОГРН */
  ogrnDate: Scalars['Float']['output'];
  /** Код ОКАТО */
  okato: Scalars['String']['output'];
  /** Код ОКПО */
  okpo: Scalars['String']['output'];
  /** Код ОКТМО */
  oktmo: Scalars['String']['output'];
  /** Адрес регистрации */
  registrationAddress: Scalars['String']['output'];
  /** Дата регистрации */
  registrationDate: Scalars['Float']['output'];
  /** Краткое наименование */
  shortName: Scalars['String']['output'];
  /** Cтатус организации */
  state: Organization_State;
  /** Статус компании в системе */
  status: Company_Status;
  /** Тип организации */
  type: Type_Of_Organization;
  updatedAt: Scalars['Date']['output'];
};

export type CompanyCandidate = {
  __typename?: 'CompanyCandidate';
  /** Username кандидата в ТГ */
  candidateUsername: CandidateUsername;
  /** ID Username кандидата в ТГ */
  candidateUsernameId: Scalars['ID']['output'];
  /** Компания пользователя */
  company: Company;
  /** ID компании пользователя */
  companyId: Scalars['ID']['output'];
  createdAt: Scalars['Date']['output'];
  /** Email кандидата */
  email?: Maybe<Scalars['String']['output']>;
  /** ФИО кандидата как его заполнила компания */
  fullName: Scalars['String']['output'];
  /** Грейд кандидата */
  grade?: Maybe<Grade>;
  id: Scalars['ID']['output'];
  /** Тестирования кандидата */
  trials?: Maybe<Array<Trial>>;
  updatedAt: Scalars['Date']['output'];
};

export type CompanyStatusHistory = {
  __typename?: 'CompanyStatusHistory';
  /** Компания пользователя */
  company: Company;
  /** ID компании пользователя */
  companyId: Scalars['ID']['output'];
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  /** Новый статус компании */
  newStatus: Company_Status;
  updatedAt: Scalars['Date']['output'];
};

export type CompanyUser = {
  __typename?: 'CompanyUser';
  /** Компания пользователя */
  company: Company;
  /** ID компании пользователя */
  companyId: Scalars['ID']['output'];
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  /** Флаг подтверждения */
  isConfirmed: Scalars['Boolean']['output'];
  /** Является ли создателем компании */
  isCreator: Scalars['Boolean']['output'];
  /** Является ли руководителем */
  isSupervisor: Scalars['Boolean']['output'];
  /** Должность */
  position: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
  /** Email пользователя */
  userEmail: UserEmail;
  /** ID email пользователя */
  userEmailId: Scalars['ID']['output'];
};

export type Competence = {
  __typename?: 'Competence';
  /** Блоки компетенции */
  CompetenceKnowledgeBlocks?: Maybe<Array<KnowledgeBlockCompetence>>;
  /** Компания */
  company: Company;
  /** ID компании */
  companyId: Scalars['ID']['output'];
  /** Карты компетенций в текущей компетенции */
  competenceMaps?: Maybe<Array<CompetencyMap>>;
  createdAt: Scalars['Date']['output'];
  /** Грейды компетенции */
  grades?: Maybe<Array<Grade>>;
  id: Scalars['ID']['output'];
  /** Флаг удаления */
  isDeleted: Scalars['Boolean']['output'];
  /** Специализация */
  programingLanguage: Scalars['String']['output'];
  /** Название компетенции */
  title: Scalars['String']['output'];
  /** Топики компетенции */
  topics?: Maybe<Array<Topic>>;
  updatedAt: Scalars['Date']['output'];
};

export type CompetencyMap = {
  __typename?: 'CompetencyMap';
  /** Количество блоков знаний в карту компетенций */
  blocksCount: Scalars['Float']['output'];
  /** Комментарий к карте компетенций */
  comment?: Maybe<Scalars['String']['output']>;
  /** Компания */
  company: Company;
  /** ID компании */
  companyId: Scalars['ID']['output'];
  /** Скопированные карты компетенций */
  copies?: Maybe<Array<CompetencyMap>>;
  createdAt: Scalars['Date']['output'];
  /** Дата "мягкого" удаления */
  deletedAt?: Maybe<Scalars['Date']['output']>;
  demoCode?: Maybe<Scalars['String']['output']>;
  /** Грейды карты компетенций */
  grades?: Maybe<Array<Grade>>;
  id: Scalars['ID']['output'];
  /** Флаг удаления */
  isDeleted: Scalars['Boolean']['output'];
  /** Использовать проверку репозитория */
  isRepositoryCheck: Scalars['Boolean']['output'];
  /** Флаг примера */
  isSample: Scalars['Boolean']['output'];
  /** Валидна ли карта компетенций (есть ли во всех топиках подтверждённые вопросы) */
  isValid?: Maybe<Scalars['Boolean']['output']>;
  /** Блоки карты компетенций */
  knowledgeBlocks?: Maybe<Array<KnowledgeBlock>>;
  /** Оригинальная карта компетенций */
  originalCompetencyMap?: Maybe<CompetencyMap>;
  /** ID оригинальной карты компетенции */
  originalCompetencyMapId?: Maybe<Scalars['ID']['output']>;
  /** Специализация карты компетенций */
  specialization: Scalars['String']['output'];
  /** Название карты компетенций */
  title: Scalars['String']['output'];
  /** Тестирования карты компетенции */
  trials?: Maybe<Array<Trial>>;
  /** Количество тестирований с данной картой компетенций */
  trialsCount: Scalars['Float']['output'];
  updatedAt: Scalars['Date']['output'];
  /** Использовать карту компетенций в боте */
  useInBot: Scalars['Boolean']['output'];
};

export type CopyCompetenceMapInputDto = {
  /** Id карты компетенций */
  competencyMapId: Scalars['ID']['input'];
  /** Название новой карты компетенций */
  title?: InputMaybe<Scalars['String']['input']>;
};

export type CreateOrUpdateBlockInput = {
  /** Список id компетенций */
  competencesIds: Array<Scalars['String']['input']>;
  /** Id блока */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Название блока */
  title: Scalars['String']['input'];
};

export type CreateOrUpdateCompanyCandidateInput = {
  /** Id компании */
  companyId: Scalars['String']['input'];
  /** Email кандидата */
  email?: InputMaybe<Scalars['String']['input']>;
  /** ФИО кандидата */
  fullName: Scalars['String']['input'];
  /** Грейд кандидата */
  grade?: InputMaybe<Grade>;
  /** Id кандидата */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Профиль телеграм кандидата */
  telegramUsername: Scalars['String']['input'];
};

export type CreateOrUpdateCompanyInput = {
  /** Дата последних изменений */
  actualityDate?: InputMaybe<Scalars['Float']['input']>;
  /** Полное наименование */
  fullName: Scalars['String']['input'];
  /** Id компании */
  id?: InputMaybe<Scalars['String']['input']>;
  /** ИНН */
  inn: Scalars['String']['input'];
  /** КПП */
  kpp: Scalars['String']['input'];
  /** Дата ликвидации */
  liquidationDate?: InputMaybe<Scalars['Float']['input']>;
  /** ФИО руководителя */
  managerFio: Scalars['String']['input'];
  /** Должность руководителя */
  managerPosition: Scalars['String']['input'];
  /** ОГРН */
  ogrn: Scalars['String']['input'];
  /** Дата выдачи ОГРН */
  ogrnDate: Scalars['Float']['input'];
  /** Код ОКАТО */
  okato: Scalars['String']['input'];
  /** Код ОКПО */
  okpo: Scalars['String']['input'];
  /** Код ОКТМО */
  oktmo: Scalars['String']['input'];
  /** Адрес регистрации */
  registrationAddress: Scalars['String']['input'];
  /** Дата регистрации */
  registrationDate: Scalars['Float']['input'];
  /** Краткое наименование */
  shortName: Scalars['String']['input'];
  /** Cтатус организации */
  state: Organization_State;
  /** Тип организации */
  type: Type_Of_Organization;
  /** Должность пользователя, заносящего компанию */
  userPosition: Scalars['String']['input'];
};

export type CreateOrUpdateCompetenceInput = {
  /** Id компании */
  companyId: Scalars['String']['input'];
  /** Id компетенции */
  id?: InputMaybe<Scalars['String']['input']>;
  /** Язык программирования */
  programingLanguage: Scalars['String']['input'];
  /** Название компетенции */
  title: Scalars['String']['input'];
};

export type CreateOrUpdateCompetencyMapInput = {
  /** Блоки карты компетенций */
  blocks: Array<CreateOrUpdateBlockInput>;
  /** Комментарий к карте компетенций */
  comment?: InputMaybe<Scalars['String']['input']>;
  /** Id компании */
  companyId: Scalars['String']['input'];
  demoCode?: InputMaybe<Scalars['String']['input']>;
  /** Id карты компетенций */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Использовать проверку репозитория */
  isRepositoryCheck: Scalars['Boolean']['input'];
  /** Специализация карты компетенций */
  specialization: Scalars['String']['input'];
  /** Название карты компетенций */
  title: Scalars['String']['input'];
  /** Использовать карту компетенций в боте */
  useInBot?: Scalars['Boolean']['input'];
};

export type CreateOrUpdateQuestionInput = {
  /** Критерии вопроса */
  criterion: Array<Scalars['String']['input']>;
  /** Грейд вопроса */
  grade: Grade;
  /** Id вопроса */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Язык вопроса */
  language: Question_Lang;
  /** Подтип вопроса */
  subtype?: InputMaybe<Scalars['String']['input']>;
  /** Текст вопроса */
  text: Scalars['String']['input'];
  /** Количество секунд отведённых на вопрос */
  time: Scalars['Float']['input'];
  /** Id топика */
  topicId: Scalars['ID']['input'];
  /** Тип вопроса */
  type: Question_Type;
};

export type CreateOrUpdateTopicInput = {
  /** ID компетенции */
  competenceId: Scalars['ID']['input'];
  /** Id топика */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Название топика */
  title: Scalars['String']['input'];
};

export type CreateOrUpdateTrialInput = {
  /** Комментарий к тестированию */
  comment?: InputMaybe<Scalars['String']['input']>;
  /** Id кандидата компании */
  companyCandidateId: Scalars['ID']['input'];
  /** Id карты компетенций */
  competencyMapId: Scalars['ID']['input'];
  /** Id тестирования */
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateUserInput = {
  /** Email */
  email: Scalars['String']['input'];
  /** Имя */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** Фамилия */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** Отчество */
  middleName?: InputMaybe<Scalars['String']['input']>;
  /** Пароль */
  password: Scalars['String']['input'];
};

export type CriterionAssessment = {
  __typename?: 'CriterionAssessment';
  criteria: Scalars['String']['output'];
  decision: Scalars['String']['output'];
  explanation: Scalars['String']['output'];
};

export enum Grade {
  Junior = 'Junior',
  Middle = 'Middle',
  Senior = 'Senior'
}

export type InitGenerateQuestionsInput = {
  /** Грейд вопроса */
  grade: Grade;
  /** Язык вопроса */
  language: Question_Lang;
  /** Подтипа практического вопроса */
  questionSubtype?: InputMaybe<Scalars['String']['input']>;
  /** Тип вопроса */
  questionType: Question_Type;
  /** Id топика */
  topicId: Scalars['ID']['input'];
};

export type KnowledgeBlock = {
  __typename?: 'KnowledgeBlock';
  /** Карта компетенции */
  competencyMap: CompetencyMap;
  /** ID карты компетенции */
  competencyMapId: Scalars['ID']['output'];
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  /** Компетенции блока */
  knowledgeBlockCompetences?: Maybe<Array<KnowledgeBlockCompetence>>;
  /** Порядок блока в карте компетенций */
  order: Scalars['Int']['output'];
  /** Название блока */
  title: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
};

export type KnowledgeBlockCompetence = {
  __typename?: 'KnowledgeBlockCompetence';
  /** Компетенция */
  competence: Competence;
  /** ID Компетенции */
  competenceId: Scalars['ID']['output'];
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  /** Блок */
  knowledgeBlock: KnowledgeBlock;
  /** ID Блока */
  knowledgeBlockId: Scalars['ID']['output'];
  /** Порядок компетенции в блоке */
  order: Scalars['Int']['output'];
  updatedAt: Scalars['Date']['output'];
};

export type ListCompanyCandidateFilterInput = {
  fullName?: InputMaybe<Scalars['String']['input']>;
  telegramUsername?: InputMaybe<Scalars['String']['input']>;
};

export type ListCompetenceFilterInput = {
  /** Дата создания компетенци, отправлять нужное число в локальном часовом поясе c временем 00:00:000 */
  createdAt?: InputMaybe<Scalars['Date']['input']>;
  /** Грейды */
  grades?: InputMaybe<Array<Grade>>;
  /** Название компетенци */
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ListCompetencyMapFilterInput = {
  /** Дата создания карты компетенций, отправлять нужное число в локальном часовом поясе c временем 00:00:000 */
  createdAt?: InputMaybe<Scalars['Date']['input']>;
  /** Грейды */
  grades?: InputMaybe<Array<Grade>>;
  /** Название карты компетенций */
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ListTopicFilterInput = {
  /** Id компетенции */
  competenceId: Scalars['ID']['input'];
  /** Дата создания топика */
  createdAt?: InputMaybe<Scalars['Date']['input']>;
  /** Грейды */
  grades?: InputMaybe<Array<Grade>>;
  /** Название топика */
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ListTrialFilterInput = {
  /** ФИО кандидата */
  candidateFullName?: InputMaybe<Scalars['String']['input']>;
  /** Название карты компетенций */
  competencyMapTitle?: InputMaybe<Scalars['String']['input']>;
  /** Дата завершения тестирования, отправлять нужное число в локальном часовом поясе c временем 00:00:000 */
  completedAt?: InputMaybe<Scalars['Date']['input']>;
  /** Статус тестирования */
  status?: InputMaybe<Trial_Status>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Отправить вопрос в подтвержденные или наоборот */
  confirmOrRejectQuestion: Question;
  /** Создание новой карты компетенций на основе другой */
  copyCompetencyMap: CompetencyMap;
  /** Создание или обновление компании */
  createOrUpdateCompany: Company;
  /** Создание или обновление кандидата компании */
  createOrUpdateCompanyCandidate: CompanyCandidate;
  /** Создание или обновление компетенции */
  createOrUpdateCompetence: Competence;
  /** Создание или обновление карты компетенций */
  createOrUpdateCompetencyMap: CompetencyMap;
  /** Создание или обновление вопроса */
  createOrUpdateQuestion: Question;
  /** Создание или обновлений топика */
  createOrUpdateTopic: Topic;
  /** Создание или обновление тестирования */
  createOrUpdateTrial: Trial;
  /** Регистрация пользователя */
  createUser: AuthTokensDto;
  /** Удаление компетенции */
  deleteCompetence: Scalars['Boolean']['output'];
  /** Удаление карты компетенций */
  deleteCompetencyMap: Scalars['Boolean']['output'];
  /** Удаление вопроса */
  deleteQuestion: Scalars['Boolean']['output'];
  /** Удаление топика */
  deleteTopic: Scalars['Boolean']['output'];
  /** Генерация вопроса для топика */
  initGenerateQuestions: Scalars['Boolean']['output'];
  /** Авторизация связке номер email + пароль */
  loginByCredentials: AuthTokensDto;
  /** Обновление токенов авторизации */
  refreshTokens: AuthTokensDto;
  /** Обновление статуса тестирования */
  setTrialStatus: Trial;
  /** Обновление данных пользователя */
  updateUser: User;
};


export type MutationConfirmOrRejectQuestionArgs = {
  questionId: Scalars['ID']['input'];
};


export type MutationCopyCompetencyMapArgs = {
  input: CopyCompetenceMapInputDto;
};


export type MutationCreateOrUpdateCompanyArgs = {
  input: CreateOrUpdateCompanyInput;
};


export type MutationCreateOrUpdateCompanyCandidateArgs = {
  input: CreateOrUpdateCompanyCandidateInput;
};


export type MutationCreateOrUpdateCompetenceArgs = {
  input: CreateOrUpdateCompetenceInput;
};


export type MutationCreateOrUpdateCompetencyMapArgs = {
  input: CreateOrUpdateCompetencyMapInput;
};


export type MutationCreateOrUpdateQuestionArgs = {
  input: CreateOrUpdateQuestionInput;
};


export type MutationCreateOrUpdateTopicArgs = {
  input: CreateOrUpdateTopicInput;
};


export type MutationCreateOrUpdateTrialArgs = {
  input: CreateOrUpdateTrialInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationDeleteCompetenceArgs = {
  competenceId: Scalars['ID']['input'];
};


export type MutationDeleteCompetencyMapArgs = {
  competencyMapId: Scalars['ID']['input'];
};


export type MutationDeleteQuestionArgs = {
  questionId: Scalars['ID']['input'];
};


export type MutationDeleteTopicArgs = {
  topicId: Scalars['ID']['input'];
};


export type MutationInitGenerateQuestionsArgs = {
  input: InitGenerateQuestionsInput;
};


export type MutationLoginByCredentialsArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationRefreshTokensArgs = {
  refreshToken: Scalars['String']['input'];
};


export type MutationSetTrialStatusArgs = {
  input: SetTrialStatusInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserDataInput;
};

export enum Organization_State {
  Active = 'ACTIVE',
  Bankrupt = 'BANKRUPT',
  Liquidated = 'LIQUIDATED',
  Liquidating = 'LIQUIDATING',
  Reorganizing = 'REORGANIZING'
}

export enum OrderType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type PaginatedCompanyCandidate = {
  __typename?: 'PaginatedCompanyCandidate';
  /** Данные на указанной странице */
  data?: Maybe<Array<CompanyCandidate>>;
  /** Есть ли следующая страница */
  hasNextPage: Scalars['Boolean']['output'];
  /** Есть ли предыдущая странца */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Номер страницы (отсчёт с 0) */
  limit: Scalars['Float']['output'];
  /** Общее число записей */
  page: Scalars['Float']['output'];
  /** Ограничение количества записей на странице */
  totalCount: Scalars['Float']['output'];
};

export type PaginatedCompetence = {
  __typename?: 'PaginatedCompetence';
  /** Данные на указанной странице */
  data?: Maybe<Array<Competence>>;
  /** Есть ли следующая страница */
  hasNextPage: Scalars['Boolean']['output'];
  /** Есть ли предыдущая странца */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Номер страницы (отсчёт с 0) */
  limit: Scalars['Float']['output'];
  /** Общее число записей */
  page: Scalars['Float']['output'];
  /** Ограничение количества записей на странице */
  totalCount: Scalars['Float']['output'];
};

export type PaginatedCompetencyMap = {
  __typename?: 'PaginatedCompetencyMap';
  /** Данные на указанной странице */
  data?: Maybe<Array<CompetencyMap>>;
  /** Есть ли следующая страница */
  hasNextPage: Scalars['Boolean']['output'];
  /** Есть ли предыдущая странца */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Номер страницы (отсчёт с 0) */
  limit: Scalars['Float']['output'];
  /** Общее число записей */
  page: Scalars['Float']['output'];
  /** Ограничение количества записей на странице */
  totalCount: Scalars['Float']['output'];
};

export type PaginatedTopic = {
  __typename?: 'PaginatedTopic';
  /** Данные на указанной странице */
  data?: Maybe<Array<Topic>>;
  /** Есть ли следующая страница */
  hasNextPage: Scalars['Boolean']['output'];
  /** Есть ли предыдущая странца */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Номер страницы (отсчёт с 0) */
  limit: Scalars['Float']['output'];
  /** Общее число записей */
  page: Scalars['Float']['output'];
  /** Ограничение количества записей на странице */
  totalCount: Scalars['Float']['output'];
};

export type PaginatedTrial = {
  __typename?: 'PaginatedTrial';
  /** Данные на указанной странице */
  data?: Maybe<Array<Trial>>;
  /** Есть ли следующая страница */
  hasNextPage: Scalars['Boolean']['output'];
  /** Есть ли предыдущая странца */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Номер страницы (отсчёт с 0) */
  limit: Scalars['Float']['output'];
  /** Общее число записей */
  page: Scalars['Float']['output'];
  /** Ограничение количества записей на странице */
  totalCount: Scalars['Float']['output'];
};

export enum PlatformTrialListOrderFields {
  CompletedAt = 'completedAt',
  CreatedAt = 'createdAt'
}

export type PlatformTrialListOrderInput = {
  /** Поле сортировки */
  field: PlatformTrialListOrderFields;
  /** Порядок сортировка */
  order: OrderType;
};

export enum Question_Lang {
  English = 'english',
  Kazakh = 'kazakh',
  Russian = 'russian'
}

export enum Question_Type {
  Practical = 'practical',
  Theoretical = 'theoretical'
}

export type Query = {
  __typename?: 'Query';
  /** Компания по Id */
  company?: Maybe<Company>;
  /** Кандидат компании по Id */
  companyCandidate?: Maybe<CompanyCandidate>;
  /** Список кандидатов компании */
  companyCandidates: PaginatedCompanyCandidate;
  /** Компетенция по Id */
  competence?: Maybe<Competence>;
  /** Специализации компетенции */
  competenceSpecializations: Array<Scalars['String']['output']>;
  /** Список компетенций */
  competences: PaginatedCompetence;
  /** Карта компетенций по ID */
  competencyMap?: Maybe<CompetencyMap>;
  /** Список карт компетенций */
  competencyMaps: PaginatedCompetencyMap;
  /** Текущий пользователь */
  me: User;
  /** Подтипы практического вопроса */
  questionSubtypes: Array<Scalars['String']['output']>;
  /** Топик по Id */
  topic?: Maybe<Topic>;
  /** Список топиков */
  topics: PaginatedTopic;
  /** Тестирование по ID */
  trial?: Maybe<Trial>;
  /** Список тестирований */
  trials: PaginatedTrial;
  /** Компании пользователя */
  userCompanies?: Maybe<Array<Company>>;
};


export type QueryCompanyCandidateArgs = {
  companyCandidateId: Scalars['ID']['input'];
};


export type QueryCompanyCandidatesArgs = {
  input: ListCompanyCandidateFilterInput;
  limit: Scalars['Float']['input'];
  page: Scalars['Float']['input'];
};


export type QueryCompetenceArgs = {
  competenceId: Scalars['ID']['input'];
};


export type QueryCompetencesArgs = {
  filter?: InputMaybe<ListCompetenceFilterInput>;
  limit: Scalars['Float']['input'];
  page: Scalars['Float']['input'];
};


export type QueryCompetencyMapArgs = {
  competencyMapId: Scalars['ID']['input'];
};


export type QueryCompetencyMapsArgs = {
  filter?: InputMaybe<ListCompetencyMapFilterInput>;
  limit: Scalars['Float']['input'];
  page: Scalars['Float']['input'];
};


export type QueryTopicArgs = {
  topicId: Scalars['ID']['input'];
};


export type QueryTopicsArgs = {
  filter: ListTopicFilterInput;
  limit: Scalars['Float']['input'];
  page: Scalars['Float']['input'];
};


export type QueryTrialArgs = {
  trialId: Scalars['ID']['input'];
};


export type QueryTrialsArgs = {
  filter?: InputMaybe<ListTrialFilterInput>;
  limit: Scalars['Float']['input'];
  order: PlatformTrialListOrderInput;
  page: Scalars['Float']['input'];
};

export type Question = {
  __typename?: 'Question';
  /** Ответы кандидата по тестированию */
  answers?: Maybe<Array<TrialAnswer>>;
  /** Подтверждён ли вопрос */
  confirmation: Scalars['Boolean']['output'];
  createdAt: Scalars['Date']['output'];
  /** Критерии вопроса */
  criterion: Array<Scalars['String']['output']>;
  /** Грейд вопроса */
  grade: Grade;
  id: Scalars['ID']['output'];
  /** Флаг удаления */
  isDeleted: Scalars['Boolean']['output'];
  /** Язык вопроса */
  language: Question_Lang;
  /** Микрокритерии вопроса */
  microCriterion?: Maybe<Array<Scalars['String']['output']>>;
  /** Рекомендуемое количество секунд отведённых на вопрос */
  recommendedTime?: Maybe<Scalars['Float']['output']>;
  /** Подтип вопроса */
  subtype?: Maybe<Scalars['String']['output']>;
  /** Текст вопроса */
  text: Scalars['String']['output'];
  /** Количество секунд отведённых на вопрос */
  time: Scalars['Float']['output'];
  /** Топик */
  topic: Topic;
  /** ID топика */
  topicId: Scalars['ID']['output'];
  /** Тип вопроса */
  type: Question_Type;
  updatedAt: Scalars['Date']['output'];
};

export type SetTrialStatusInput = {
  newStatus: Trial_Status;
  trialId: Scalars['ID']['input'];
};

export enum Trial_Status {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Created = 'CREATED',
  Received = 'RECEIVED',
  Started = 'STARTED'
}

export enum Type_Of_Organization {
  Individual = 'INDIVIDUAL',
  Legal = 'LEGAL'
}

export type Topic = {
  __typename?: 'Topic';
  /** Компетенция */
  competence: Competence;
  /** ID компетенции */
  competenceId: Scalars['ID']['output'];
  /** Количество подтвержденных вопросов */
  confirmedQuestionsCount?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['Date']['output'];
  /** Грейды топика */
  grades?: Maybe<Array<Grade>>;
  id: Scalars['ID']['output'];
  /** Флаг удаления */
  isDeleted: Scalars['Boolean']['output'];
  /** Порядок топика в компетенции */
  order: Scalars['Int']['output'];
  /** Вопросы топика */
  questions?: Maybe<Array<Question>>;
  /** Название топика */
  title: Scalars['String']['output'];
  /** Ответ по тестированию для топика */
  trialAnswer?: Maybe<TrialAnswer>;
  updatedAt: Scalars['Date']['output'];
};


export type TopicTrialAnswerArgs = {
  trialId: Scalars['ID']['input'];
};

export type Trial = {
  __typename?: 'Trial';
  /** ID чата с ботом в telegram */
  botChatId?: Maybe<Scalars['Float']['output']>;
  /** Результат кандидата по тестированию */
  candidateScore?: Maybe<Scalars['Float']['output']>;
  /** Комментарий к тестированию */
  comment?: Maybe<Scalars['String']['output']>;
  /** Кандидат компании */
  companyCandidate: CompanyCandidate;
  /** ID Кандидата компании */
  companyCandidateId: Scalars['ID']['output'];
  /** Карта компетенций */
  competencyMap: CompetencyMap;
  /** ID Карты компетенций */
  competencyMapId: Scalars['ID']['output'];
  /** Дата завершения тестирования */
  completedAt?: Maybe<Scalars['Date']['output']>;
  createdAt: Scalars['Date']['output'];
  /** Грейды тестирования */
  grades?: Maybe<Array<Grade>>;
  id: Scalars['ID']['output'];
  /** Числовой id тестирования */
  numericId: Scalars['Float']['output'];
  /** Генерировать вопросы картинкой */
  questionsAsImage: Scalars['Boolean']['output'];
  /** Статус тестирования */
  status: Trial_Status;
  /** Ответы кандидата по тестированию */
  trialAnswers?: Maybe<Array<TrialAnswer>>;
  /** Результаты проверки репозитория */
  trialRepositoryChecks: Array<TrialRepositoryCheck>;
  /** История статусов тестирования */
  trialStatusHistory?: Maybe<Array<TrialStatusHistory>>;
  updatedAt: Scalars['Date']['output'];
};

export type TrialAnswer = {
  __typename?: 'TrialAnswer';
  /** Ответ на вопрос */
  answer: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  /** Вердикт ответа */
  criterionAssessments?: Maybe<Array<CriterionAssessment>>;
  id: Scalars['ID']['output'];
  /** Вердикт микрокритерия */
  microcriterionAssessments?: Maybe<Array<CriterionAssessment>>;
  /** Оценка микрокритерия */
  microcriterionScore?: Maybe<Scalars['Float']['output']>;
  /** Компетенция */
  question: Question;
  /** ID Компетенции */
  questionId: Scalars['ID']['output'];
  /** Оценка ответа */
  score?: Maybe<Scalars['Float']['output']>;
  /** Количество секунд затраченных на ответ */
  time: Scalars['Float']['output'];
  /** Тестирование */
  trial: Trial;
  /** ID Тестирования */
  trialId: Scalars['ID']['output'];
  updatedAt: Scalars['Date']['output'];
};

export type TrialRepositoryCheck = {
  __typename?: 'TrialRepositoryCheck';
  /** Балл за алгоритмы */
  algorithmsScore?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['Date']['output'];
  /** Ошибка API проверки репозитория */
  error?: Maybe<Scalars['JSON']['output']>;
  /** Факты о репозитории */
  facts?: Maybe<TrialRepositoryFacts>;
  id: Scalars['ID']['output'];
  /** Балл за поддерживаемость */
  maintainabilityScore?: Maybe<Scalars['Float']['output']>;
  /** Основной язык репозитория */
  programingLanguage?: Maybe<Scalars['String']['output']>;
  /** Балл за читаемость */
  readabilityScore?: Maybe<Scalars['Float']['output']>;
  /** Ссылка на репозиторий */
  repoUrl?: Maybe<Scalars['String']['output']>;
  /** ID в API проверки репозитория */
  taskId?: Maybe<Scalars['ID']['output']>;
  /** Общий балл */
  totalScore?: Maybe<Scalars['Float']['output']>;
  /** Тестирование */
  trial: Trial;
  /** ID Тестирования */
  trialId: Scalars['ID']['output'];
  updatedAt: Scalars['Date']['output'];
};

export type TrialRepositoryFacts = {
  __typename?: 'TrialRepositoryFacts';
  developers_count?: Maybe<Scalars['Float']['output']>;
  first_release_date?: Maybe<Scalars['Date']['output']>;
  forks_count?: Maybe<Scalars['Float']['output']>;
  is_scoring_available?: Maybe<Scalars['Boolean']['output']>;
  languages?: Maybe<Scalars['JSON']['output']>;
  main_language?: Maybe<Scalars['String']['output']>;
  repo_url?: Maybe<Scalars['String']['output']>;
  stars_count?: Maybe<Scalars['Float']['output']>;
};

export type TrialStatusHistory = {
  __typename?: 'TrialStatusHistory';
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  /** Новый статус тестирования */
  newStatus: Trial_Status;
  /** Тестирование */
  trial: Trial;
  /** ID Тестирования */
  trialId: Scalars['ID']['output'];
  updatedAt: Scalars['Date']['output'];
};

export type UpdateUserDataInput = {
  /** Email */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Имя */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** Фамилия */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** Отчество */
  middleName?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  createdAt: Scalars['Date']['output'];
  /** Имя пользователя */
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Является ли пользователь админом */
  isAdmin: Scalars['Boolean']['output'];
  /** Заблокирован ли пользователь */
  isBlocked: Scalars['Boolean']['output'];
  /** Фамилия пользователя */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Отчество пользователя */
  middleName?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
  /** Eмail пользователя */
  userEmail: UserEmail;
  /** ID email пользователя */
  userEmailId: Scalars['ID']['output'];
};

export type UserEmail = {
  __typename?: 'UserEmail';
  /** Пользователи компании */
  companyUsers?: Maybe<Array<CompanyUser>>;
  createdAt: Scalars['Date']['output'];
  /** Email пользователя */
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['Date']['output'];
  /** Пользователь */
  user: User;
};

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: { __typename?: 'AuthTokensDto', accessToken: string, refreshToken: string } };

export type LoginByCredentialsMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type LoginByCredentialsMutation = { __typename?: 'Mutation', loginByCredentials: { __typename?: 'AuthTokensDto', accessToken: string, refreshToken: string } };

export type CompanyCandidatesQueryVariables = Exact<{
  input: ListCompanyCandidateFilterInput;
  limit: Scalars['Float']['input'];
  page: Scalars['Float']['input'];
}>;


export type CompanyCandidatesQuery = { __typename?: 'Query', companyCandidates: { __typename?: 'PaginatedCompanyCandidate', totalCount: number, data?: Array<{ __typename?: 'CompanyCandidate', id: string, fullName: string, candidateUsername: { __typename?: 'CandidateUsername', id: string, telegramUsername: string, candidate?: { __typename?: 'Candidate', id: string, phoneNumber?: string | null } | null, companyCandidate?: Array<{ __typename?: 'CompanyCandidate', id: string, companyId: string, email?: string | null, grade?: Grade | null }> | null } }> | null } };

export type CreateOrUpdateCompanyCandidateMutationVariables = Exact<{
  input: CreateOrUpdateCompanyCandidateInput;
}>;


export type CreateOrUpdateCompanyCandidateMutation = { __typename?: 'Mutation', createOrUpdateCompanyCandidate: { __typename?: 'CompanyCandidate', id: string, fullName: string, email?: string | null, grade?: Grade | null, candidateUsername: { __typename?: 'CandidateUsername', id: string, telegramUsername: string, candidate?: { __typename?: 'Candidate', id: string, phoneNumber?: string | null } | null } } };

export type CreateOrUpdateCompanyMutationVariables = Exact<{
  input: CreateOrUpdateCompanyInput;
}>;


export type CreateOrUpdateCompanyMutation = { __typename?: 'Mutation', createOrUpdateCompany: { __typename?: 'Company', id: string, inn: string, shortName: string, fullName: string, kpp: string, ogrn: string, ogrnDate: number, type: Type_Of_Organization, managerFio: string, managerPosition: string, okpo: string, okato: string, oktmo: string, registrationAddress: string, registrationDate: number, status: Company_Status, state: Organization_State, currentUserPosition?: string | null } };

export type CompanyQueryVariables = Exact<{ [key: string]: never; }>;


export type CompanyQuery = { __typename?: 'Query', company?: { __typename?: 'Company', id: string, identification: string, inn: string, shortName: string, fullName: string, kpp: string, ogrn: string, ogrnDate: number, type: Type_Of_Organization, managerFio: string, managerPosition: string, okpo: string, okato: string, oktmo: string, registrationAddress: string, registrationDate: number, status: Company_Status, state: Organization_State, currentUserPosition?: string | null } | null };

export type CreateOrUpdateCompetenceMutationVariables = Exact<{
  input: CreateOrUpdateCompetenceInput;
}>;


export type CreateOrUpdateCompetenceMutation = { __typename?: 'Mutation', createOrUpdateCompetence: { __typename?: 'Competence', id: string, title: string, grades?: Array<Grade> | null, createdAt: any, updatedAt: any, programingLanguage: string, competenceMaps?: Array<{ __typename?: 'CompetencyMap', id: string, title: string }> | null } };

export type DeleteCompetenceMutationVariables = Exact<{
  competenceId: Scalars['ID']['input'];
}>;


export type DeleteCompetenceMutation = { __typename?: 'Mutation', deleteCompetence: boolean };

export type CompetenceQueryVariables = Exact<{
  competenceId: Scalars['ID']['input'];
}>;


export type CompetenceQuery = { __typename?: 'Query', competence?: { __typename?: 'Competence', id: string, title: string, grades?: Array<Grade> | null, createdAt: any, updatedAt: any, programingLanguage: string, competenceMaps?: Array<{ __typename?: 'CompetencyMap', id: string, title: string }> | null } | null };

export type CompetenceOptionsQueryVariables = Exact<{
  filter?: InputMaybe<ListCompetenceFilterInput>;
  limit?: Scalars['Float']['input'];
  page?: Scalars['Float']['input'];
}>;


export type CompetenceOptionsQuery = { __typename?: 'Query', competences: { __typename?: 'PaginatedCompetence', totalCount: number, data?: Array<{ __typename?: 'Competence', id: string, title: string, programingLanguage: string, grades?: Array<Grade> | null }> | null } };

export type CompetenceOptionsWithTopicsQueryVariables = Exact<{
  filter?: InputMaybe<ListCompetenceFilterInput>;
  limit?: Scalars['Float']['input'];
  page?: Scalars['Float']['input'];
}>;


export type CompetenceOptionsWithTopicsQuery = { __typename?: 'Query', competences: { __typename?: 'PaginatedCompetence', totalCount: number, data?: Array<{ __typename?: 'Competence', id: string, title: string, grades?: Array<Grade> | null, programingLanguage: string, topics?: Array<{ __typename?: 'Topic', id: string, title: string }> | null }> | null } };

export type CompetenceSpecializationsQueryVariables = Exact<{ [key: string]: never; }>;


export type CompetenceSpecializationsQuery = { __typename?: 'Query', competenceSpecializations: Array<string> };

export type CompetencesQueryVariables = Exact<{
  filter?: InputMaybe<ListCompetenceFilterInput>;
  limit: Scalars['Float']['input'];
  page: Scalars['Float']['input'];
}>;


export type CompetencesQuery = { __typename?: 'Query', competences: { __typename?: 'PaginatedCompetence', totalCount: number, data?: Array<{ __typename?: 'Competence', id: string, title: string, grades?: Array<Grade> | null, createdAt: any, updatedAt: any, topics?: Array<{ __typename?: 'Topic', id: string }> | null }> | null } };

export type CopyCompetencyMapMutationVariables = Exact<{
  input: CopyCompetenceMapInputDto;
}>;


export type CopyCompetencyMapMutation = { __typename?: 'Mutation', copyCompetencyMap: { __typename?: 'CompetencyMap', id: string } };

export type CreateOrUpdateCompetencyMapMutationVariables = Exact<{
  input: CreateOrUpdateCompetencyMapInput;
}>;


export type CreateOrUpdateCompetencyMapMutation = { __typename?: 'Mutation', createOrUpdateCompetencyMap: { __typename?: 'CompetencyMap', id: string, title: string, specialization: string, comment?: string | null, createdAt: any, updatedAt: any, grades?: Array<Grade> | null, useInBot: boolean, isRepositoryCheck: boolean, demoCode?: string | null, knowledgeBlocks?: Array<{ __typename?: 'KnowledgeBlock', id: string, title: string, knowledgeBlockCompetences?: Array<{ __typename?: 'KnowledgeBlockCompetence', id: string, competence: { __typename?: 'Competence', id: string, title: string, topics?: Array<{ __typename?: 'Topic', id: string, title: string }> | null } }> | null }> | null } };

export type DeleteCompetencyMapMutationVariables = Exact<{
  competencyMapId: Scalars['ID']['input'];
}>;


export type DeleteCompetencyMapMutation = { __typename?: 'Mutation', deleteCompetencyMap: boolean };

export type CompetencyMapQueryVariables = Exact<{
  competencyMapId: Scalars['ID']['input'];
}>;


export type CompetencyMapQuery = { __typename?: 'Query', competencyMap?: { __typename?: 'CompetencyMap', id: string, title: string, specialization: string, comment?: string | null, createdAt: any, updatedAt: any, grades?: Array<Grade> | null, useInBot: boolean, demoCode?: string | null, isRepositoryCheck: boolean, originalCompetencyMap?: { __typename?: 'CompetencyMap', id: string, title: string } | null, knowledgeBlocks?: Array<{ __typename?: 'KnowledgeBlock', id: string, title: string, knowledgeBlockCompetences?: Array<{ __typename?: 'KnowledgeBlockCompetence', id: string, competence: { __typename?: 'Competence', id: string, title: string, topics?: Array<{ __typename?: 'Topic', id: string, title: string, questions?: Array<{ __typename?: 'Question', id: string, confirmation: boolean }> | null }> | null } }> | null }> | null } | null };

export type CompetencyMapOptionsQueryVariables = Exact<{
  filter?: InputMaybe<ListCompetencyMapFilterInput>;
  limit?: Scalars['Float']['input'];
  page?: Scalars['Float']['input'];
}>;


export type CompetencyMapOptionsQuery = { __typename?: 'Query', competencyMaps: { __typename?: 'PaginatedCompetencyMap', totalCount: number, data?: Array<{ __typename?: 'CompetencyMap', id: string, title: string, grades?: Array<Grade> | null, comment?: string | null }> | null } };

export type CompetencyMapsQueryVariables = Exact<{
  filter?: InputMaybe<ListCompetencyMapFilterInput>;
  limit: Scalars['Float']['input'];
  page: Scalars['Float']['input'];
}>;


export type CompetencyMapsQuery = { __typename?: 'Query', competencyMaps: { __typename?: 'PaginatedCompetencyMap', totalCount: number, data?: Array<{ __typename?: 'CompetencyMap', id: string, title: string, grades?: Array<Grade> | null, blocksCount: number, trialsCount: number, specialization: string, createdAt: any, updatedAt: any, isSample: boolean, knowledgeBlocks?: Array<{ __typename?: 'KnowledgeBlock', id: string, knowledgeBlockCompetences?: Array<{ __typename?: 'KnowledgeBlockCompetence', id: string, competence: { __typename?: 'Competence', id: string, topics?: Array<{ __typename?: 'Topic', id: string, questions?: Array<{ __typename?: 'Question', id: string, confirmation: boolean }> | null }> | null } }> | null }> | null }> | null } };

export type ConfirmOrRejectQuestionMutationVariables = Exact<{
  questionId: Scalars['ID']['input'];
}>;


export type ConfirmOrRejectQuestionMutation = { __typename?: 'Mutation', confirmOrRejectQuestion: { __typename?: 'Question', id: string, time: number, text: string, criterion: Array<string>, confirmation: boolean, grade: Grade, recommendedTime?: number | null, type: Question_Type, subtype?: string | null } };

export type CreateOrUpdateQuestionMutationVariables = Exact<{
  input: CreateOrUpdateQuestionInput;
}>;


export type CreateOrUpdateQuestionMutation = { __typename?: 'Mutation', createOrUpdateQuestion: { __typename?: 'Question', id: string } };

export type DeleteQuestionMutationVariables = Exact<{
  questionId: Scalars['ID']['input'];
}>;


export type DeleteQuestionMutation = { __typename?: 'Mutation', deleteQuestion: boolean };

export type GenerateQuestionsMutationVariables = Exact<{
  input: InitGenerateQuestionsInput;
}>;


export type GenerateQuestionsMutation = { __typename?: 'Mutation', initGenerateQuestions: boolean };

export type QuestionSubtypesQueryVariables = Exact<{ [key: string]: never; }>;


export type QuestionSubtypesQuery = { __typename?: 'Query', questionSubtypes: Array<string> };

export type CreateOrUpdateTrialMutationVariables = Exact<{
  input: CreateOrUpdateTrialInput;
}>;


export type CreateOrUpdateTrialMutation = { __typename?: 'Mutation', createOrUpdateTrial: { __typename?: 'Trial', id: string, comment?: string | null, status: Trial_Status, numericId: number, createdAt: any, companyCandidate: { __typename?: 'CompanyCandidate', id: string, fullName: string, email?: string | null, grade?: Grade | null, candidateUsername: { __typename?: 'CandidateUsername', id: string, telegramUsername: string, candidate?: { __typename?: 'Candidate', id: string, phoneNumber?: string | null } | null } }, competencyMap: { __typename?: 'CompetencyMap', id: string, title: string, comment?: string | null, grades?: Array<Grade> | null }, trialStatusHistory?: Array<{ __typename?: 'TrialStatusHistory', id: string, newStatus: Trial_Status, createdAt: any }> | null } };

export type SetTrialStatusMutationVariables = Exact<{
  input: SetTrialStatusInput;
}>;


export type SetTrialStatusMutation = { __typename?: 'Mutation', setTrialStatus: { __typename?: 'Trial', id: string, status: Trial_Status } };

export type TrialHeaderQueryVariables = Exact<{
  trialId: Scalars['ID']['input'];
}>;


export type TrialHeaderQuery = { __typename?: 'Query', trial?: { __typename?: 'Trial', id: string, numericId: number, status: Trial_Status, completedAt?: any | null, companyCandidate: { __typename?: 'CompanyCandidate', id: string, fullName: string } } | null };

export type TrialInfoQueryVariables = Exact<{
  trialId: Scalars['ID']['input'];
}>;


export type TrialInfoQuery = { __typename?: 'Query', trial?: { __typename?: 'Trial', id: string, comment?: string | null, createdAt: any, completedAt?: any | null, companyCandidate: { __typename?: 'CompanyCandidate', id: string, fullName: string, email?: string | null, grade?: Grade | null, candidateUsername: { __typename?: 'CandidateUsername', id: string, telegramUsername: string, candidate?: { __typename?: 'Candidate', id: string, phoneNumber?: string | null } | null } }, competencyMap: { __typename?: 'CompetencyMap', id: string, title: string, comment?: string | null, grades?: Array<Grade> | null }, trialStatusHistory?: Array<{ __typename?: 'TrialStatusHistory', id: string, newStatus: Trial_Status, createdAt: any }> | null } | null };

export type TrialResultQueryVariables = Exact<{
  trialId: Scalars['ID']['input'];
}>;


export type TrialResultQuery = { __typename?: 'Query', trial?: { __typename?: 'Trial', id: string, candidateScore?: number | null, trialRepositoryChecks: Array<{ __typename?: 'TrialRepositoryCheck', id: string, algorithmsScore?: number | null, error?: any | null, maintainabilityScore?: number | null, readabilityScore?: number | null, repoUrl?: string | null, totalScore?: number | null, facts?: { __typename?: 'TrialRepositoryFacts', first_release_date?: any | null, main_language?: string | null, developers_count?: number | null, forks_count?: number | null, languages?: any | null, repo_url?: string | null, stars_count?: number | null } | null }>, competencyMap: { __typename?: 'CompetencyMap', id: string, knowledgeBlocks?: Array<{ __typename?: 'KnowledgeBlock', id: string, title: string, knowledgeBlockCompetences?: Array<{ __typename?: 'KnowledgeBlockCompetence', id: string, competence: { __typename?: 'Competence', id: string, title: string, topics?: Array<{ __typename?: 'Topic', id: string, title: string, trialAnswer?: { __typename?: 'TrialAnswer', id: string, score?: number | null, time: number, answer: string, question: { __typename?: 'Question', id: string, text: string }, criterionAssessments?: Array<{ __typename?: 'CriterionAssessment', criteria: string, decision: string }> | null } | null }> | null } }> | null }> | null } } | null };

export type TrialsQueryVariables = Exact<{
  filter?: InputMaybe<ListTrialFilterInput>;
  limit: Scalars['Float']['input'];
  order: PlatformTrialListOrderInput;
  page: Scalars['Float']['input'];
}>;


export type TrialsQuery = { __typename?: 'Query', trials: { __typename?: 'PaginatedTrial', totalCount: number, data?: Array<{ __typename?: 'Trial', id: string, numericId: number, status: Trial_Status, createdAt: any, completedAt?: any | null, competencyMap: { __typename?: 'CompetencyMap', id: string, title: string }, companyCandidate: { __typename?: 'CompanyCandidate', id: string, fullName: string, grade?: Grade | null } }> | null } };

export type CreateOrUpdateTopicMutationVariables = Exact<{
  input: CreateOrUpdateTopicInput;
}>;


export type CreateOrUpdateTopicMutation = { __typename?: 'Mutation', createOrUpdateTopic: { __typename?: 'Topic', id: string, title: string, grades?: Array<Grade> | null, createdAt: any, updatedAt: any, competence: { __typename?: 'Competence', id: string, title: string, programingLanguage: string }, questions?: Array<{ __typename?: 'Question', id: string, confirmation: boolean, text: string, criterion: Array<string>, time: number, microCriterion?: Array<string> | null, type: Question_Type, subtype?: string | null }> | null } };

export type DeleteTopicMutationVariables = Exact<{
  topicId: Scalars['ID']['input'];
}>;


export type DeleteTopicMutation = { __typename?: 'Mutation', deleteTopic: boolean };

export type TopicQueryVariables = Exact<{
  topicId: Scalars['ID']['input'];
}>;


export type TopicQuery = { __typename?: 'Query', topic?: { __typename?: 'Topic', id: string, title: string, grades?: Array<Grade> | null, createdAt: any, updatedAt: any, competence: { __typename?: 'Competence', id: string, title: string, programingLanguage: string }, questions?: Array<{ __typename?: 'Question', id: string, confirmation: boolean, text: string, criterion: Array<string>, time: number, grade: Grade, recommendedTime?: number | null, microCriterion?: Array<string> | null, type: Question_Type, subtype?: string | null, language: Question_Lang }> | null } | null };

export type TopicsQueryVariables = Exact<{
  filter: ListTopicFilterInput;
  limit: Scalars['Float']['input'];
  page: Scalars['Float']['input'];
}>;


export type TopicsQuery = { __typename?: 'Query', topics: { __typename?: 'PaginatedTopic', totalCount: number, data?: Array<{ __typename?: 'Topic', id: string, title: string, grades?: Array<Grade> | null, confirmedQuestionsCount?: number | null, createdAt: any, updatedAt: any, competence: { __typename?: 'Competence', id: string, title: string } }> | null } };

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserDataInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null, middleName?: string | null, userEmail: { __typename?: 'UserEmail', id: string, email: string } } };

export type UserCompaniesQueryVariables = Exact<{ [key: string]: never; }>;


export type UserCompaniesQuery = { __typename?: 'Query', userCompanies?: Array<{ __typename?: 'Company', id: string, shortName: string, currentUserPosition?: string | null }> | null };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null, middleName?: string | null, userEmail: { __typename?: 'UserEmail', id: string, email: string } } };


export const CreateUserDocument = gql`
    mutation createUser($input: CreateUserInput!) {
  createUser(input: $input) {
    accessToken
    refreshToken
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const LoginByCredentialsDocument = gql`
    mutation loginByCredentials($email: String!, $password: String!) {
  loginByCredentials(email: $email, password: $password) {
    accessToken
    refreshToken
  }
}
    `;
export type LoginByCredentialsMutationFn = Apollo.MutationFunction<LoginByCredentialsMutation, LoginByCredentialsMutationVariables>;

/**
 * __useLoginByCredentialsMutation__
 *
 * To run a mutation, you first call `useLoginByCredentialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginByCredentialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginByCredentialsMutation, { data, loading, error }] = useLoginByCredentialsMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginByCredentialsMutation(baseOptions?: Apollo.MutationHookOptions<LoginByCredentialsMutation, LoginByCredentialsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginByCredentialsMutation, LoginByCredentialsMutationVariables>(LoginByCredentialsDocument, options);
      }
export type LoginByCredentialsMutationHookResult = ReturnType<typeof useLoginByCredentialsMutation>;
export type LoginByCredentialsMutationResult = Apollo.MutationResult<LoginByCredentialsMutation>;
export type LoginByCredentialsMutationOptions = Apollo.BaseMutationOptions<LoginByCredentialsMutation, LoginByCredentialsMutationVariables>;
export const CompanyCandidatesDocument = gql`
    query companyCandidates($input: ListCompanyCandidateFilterInput!, $limit: Float!, $page: Float!) {
  companyCandidates(input: $input, limit: $limit, page: $page) {
    totalCount
    data {
      id
      fullName
      candidateUsername {
        id
        telegramUsername
        candidate {
          id
          phoneNumber
        }
        companyCandidate {
          id
          companyId
          email
          grade
        }
      }
    }
  }
}
    `;

/**
 * __useCompanyCandidatesQuery__
 *
 * To run a query within a React component, call `useCompanyCandidatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyCandidatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyCandidatesQuery({
 *   variables: {
 *      input: // value for 'input'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useCompanyCandidatesQuery(baseOptions: Apollo.QueryHookOptions<CompanyCandidatesQuery, CompanyCandidatesQueryVariables> & ({ variables: CompanyCandidatesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyCandidatesQuery, CompanyCandidatesQueryVariables>(CompanyCandidatesDocument, options);
      }
export function useCompanyCandidatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyCandidatesQuery, CompanyCandidatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyCandidatesQuery, CompanyCandidatesQueryVariables>(CompanyCandidatesDocument, options);
        }
export function useCompanyCandidatesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CompanyCandidatesQuery, CompanyCandidatesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CompanyCandidatesQuery, CompanyCandidatesQueryVariables>(CompanyCandidatesDocument, options);
        }
export type CompanyCandidatesQueryHookResult = ReturnType<typeof useCompanyCandidatesQuery>;
export type CompanyCandidatesLazyQueryHookResult = ReturnType<typeof useCompanyCandidatesLazyQuery>;
export type CompanyCandidatesSuspenseQueryHookResult = ReturnType<typeof useCompanyCandidatesSuspenseQuery>;
export type CompanyCandidatesQueryResult = Apollo.QueryResult<CompanyCandidatesQuery, CompanyCandidatesQueryVariables>;
export const CreateOrUpdateCompanyCandidateDocument = gql`
    mutation createOrUpdateCompanyCandidate($input: CreateOrUpdateCompanyCandidateInput!) {
  createOrUpdateCompanyCandidate(input: $input) {
    id
    fullName
    candidateUsername {
      id
      telegramUsername
      candidate {
        id
        phoneNumber
      }
    }
    email
    grade
  }
}
    `;
export type CreateOrUpdateCompanyCandidateMutationFn = Apollo.MutationFunction<CreateOrUpdateCompanyCandidateMutation, CreateOrUpdateCompanyCandidateMutationVariables>;

/**
 * __useCreateOrUpdateCompanyCandidateMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateCompanyCandidateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateCompanyCandidateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateCompanyCandidateMutation, { data, loading, error }] = useCreateOrUpdateCompanyCandidateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrUpdateCompanyCandidateMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateCompanyCandidateMutation, CreateOrUpdateCompanyCandidateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateCompanyCandidateMutation, CreateOrUpdateCompanyCandidateMutationVariables>(CreateOrUpdateCompanyCandidateDocument, options);
      }
export type CreateOrUpdateCompanyCandidateMutationHookResult = ReturnType<typeof useCreateOrUpdateCompanyCandidateMutation>;
export type CreateOrUpdateCompanyCandidateMutationResult = Apollo.MutationResult<CreateOrUpdateCompanyCandidateMutation>;
export type CreateOrUpdateCompanyCandidateMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateCompanyCandidateMutation, CreateOrUpdateCompanyCandidateMutationVariables>;
export const CreateOrUpdateCompanyDocument = gql`
    mutation createOrUpdateCompany($input: CreateOrUpdateCompanyInput!) {
  createOrUpdateCompany(input: $input) {
    id
    inn
    shortName
    fullName
    kpp
    ogrn
    ogrnDate
    type
    managerFio
    managerPosition
    okpo
    okato
    oktmo
    registrationAddress
    registrationDate
    status
    state
    currentUserPosition
  }
}
    `;
export type CreateOrUpdateCompanyMutationFn = Apollo.MutationFunction<CreateOrUpdateCompanyMutation, CreateOrUpdateCompanyMutationVariables>;

/**
 * __useCreateOrUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateCompanyMutation, { data, loading, error }] = useCreateOrUpdateCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrUpdateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateCompanyMutation, CreateOrUpdateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateCompanyMutation, CreateOrUpdateCompanyMutationVariables>(CreateOrUpdateCompanyDocument, options);
      }
export type CreateOrUpdateCompanyMutationHookResult = ReturnType<typeof useCreateOrUpdateCompanyMutation>;
export type CreateOrUpdateCompanyMutationResult = Apollo.MutationResult<CreateOrUpdateCompanyMutation>;
export type CreateOrUpdateCompanyMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateCompanyMutation, CreateOrUpdateCompanyMutationVariables>;
export const CompanyDocument = gql`
    query company {
  company {
    id
    identification
    inn
    shortName
    fullName
    kpp
    ogrn
    ogrnDate
    type
    managerFio
    managerPosition
    okpo
    okato
    oktmo
    registrationAddress
    registrationDate
    status
    state
    currentUserPosition
  }
}
    `;

/**
 * __useCompanyQuery__
 *
 * To run a query within a React component, call `useCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanyQuery(baseOptions?: Apollo.QueryHookOptions<CompanyQuery, CompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyQuery, CompanyQueryVariables>(CompanyDocument, options);
      }
export function useCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyQuery, CompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyQuery, CompanyQueryVariables>(CompanyDocument, options);
        }
export function useCompanySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CompanyQuery, CompanyQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CompanyQuery, CompanyQueryVariables>(CompanyDocument, options);
        }
export type CompanyQueryHookResult = ReturnType<typeof useCompanyQuery>;
export type CompanyLazyQueryHookResult = ReturnType<typeof useCompanyLazyQuery>;
export type CompanySuspenseQueryHookResult = ReturnType<typeof useCompanySuspenseQuery>;
export type CompanyQueryResult = Apollo.QueryResult<CompanyQuery, CompanyQueryVariables>;
export const CreateOrUpdateCompetenceDocument = gql`
    mutation createOrUpdateCompetence($input: CreateOrUpdateCompetenceInput!) {
  createOrUpdateCompetence(input: $input) {
    id
    title
    grades
    createdAt
    updatedAt
    competenceMaps {
      id
      title
    }
    grades
    programingLanguage
  }
}
    `;
export type CreateOrUpdateCompetenceMutationFn = Apollo.MutationFunction<CreateOrUpdateCompetenceMutation, CreateOrUpdateCompetenceMutationVariables>;

/**
 * __useCreateOrUpdateCompetenceMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateCompetenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateCompetenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateCompetenceMutation, { data, loading, error }] = useCreateOrUpdateCompetenceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrUpdateCompetenceMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateCompetenceMutation, CreateOrUpdateCompetenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateCompetenceMutation, CreateOrUpdateCompetenceMutationVariables>(CreateOrUpdateCompetenceDocument, options);
      }
export type CreateOrUpdateCompetenceMutationHookResult = ReturnType<typeof useCreateOrUpdateCompetenceMutation>;
export type CreateOrUpdateCompetenceMutationResult = Apollo.MutationResult<CreateOrUpdateCompetenceMutation>;
export type CreateOrUpdateCompetenceMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateCompetenceMutation, CreateOrUpdateCompetenceMutationVariables>;
export const DeleteCompetenceDocument = gql`
    mutation deleteCompetence($competenceId: ID!) {
  deleteCompetence(competenceId: $competenceId)
}
    `;
export type DeleteCompetenceMutationFn = Apollo.MutationFunction<DeleteCompetenceMutation, DeleteCompetenceMutationVariables>;

/**
 * __useDeleteCompetenceMutation__
 *
 * To run a mutation, you first call `useDeleteCompetenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompetenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompetenceMutation, { data, loading, error }] = useDeleteCompetenceMutation({
 *   variables: {
 *      competenceId: // value for 'competenceId'
 *   },
 * });
 */
export function useDeleteCompetenceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCompetenceMutation, DeleteCompetenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCompetenceMutation, DeleteCompetenceMutationVariables>(DeleteCompetenceDocument, options);
      }
export type DeleteCompetenceMutationHookResult = ReturnType<typeof useDeleteCompetenceMutation>;
export type DeleteCompetenceMutationResult = Apollo.MutationResult<DeleteCompetenceMutation>;
export type DeleteCompetenceMutationOptions = Apollo.BaseMutationOptions<DeleteCompetenceMutation, DeleteCompetenceMutationVariables>;
export const CompetenceDocument = gql`
    query competence($competenceId: ID!) {
  competence(competenceId: $competenceId) {
    id
    title
    grades
    createdAt
    updatedAt
    competenceMaps {
      id
      title
    }
    grades
    programingLanguage
  }
}
    `;

/**
 * __useCompetenceQuery__
 *
 * To run a query within a React component, call `useCompetenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompetenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompetenceQuery({
 *   variables: {
 *      competenceId: // value for 'competenceId'
 *   },
 * });
 */
export function useCompetenceQuery(baseOptions: Apollo.QueryHookOptions<CompetenceQuery, CompetenceQueryVariables> & ({ variables: CompetenceQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompetenceQuery, CompetenceQueryVariables>(CompetenceDocument, options);
      }
export function useCompetenceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompetenceQuery, CompetenceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompetenceQuery, CompetenceQueryVariables>(CompetenceDocument, options);
        }
export function useCompetenceSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CompetenceQuery, CompetenceQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CompetenceQuery, CompetenceQueryVariables>(CompetenceDocument, options);
        }
export type CompetenceQueryHookResult = ReturnType<typeof useCompetenceQuery>;
export type CompetenceLazyQueryHookResult = ReturnType<typeof useCompetenceLazyQuery>;
export type CompetenceSuspenseQueryHookResult = ReturnType<typeof useCompetenceSuspenseQuery>;
export type CompetenceQueryResult = Apollo.QueryResult<CompetenceQuery, CompetenceQueryVariables>;
export const CompetenceOptionsDocument = gql`
    query competenceOptions($filter: ListCompetenceFilterInput = {}, $limit: Float! = 100, $page: Float! = 0) {
  competences(filter: $filter, page: $page, limit: $limit) {
    totalCount
    data {
      id
      title
      programingLanguage
      grades
    }
  }
}
    `;

/**
 * __useCompetenceOptionsQuery__
 *
 * To run a query within a React component, call `useCompetenceOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompetenceOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompetenceOptionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useCompetenceOptionsQuery(baseOptions?: Apollo.QueryHookOptions<CompetenceOptionsQuery, CompetenceOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompetenceOptionsQuery, CompetenceOptionsQueryVariables>(CompetenceOptionsDocument, options);
      }
export function useCompetenceOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompetenceOptionsQuery, CompetenceOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompetenceOptionsQuery, CompetenceOptionsQueryVariables>(CompetenceOptionsDocument, options);
        }
export function useCompetenceOptionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CompetenceOptionsQuery, CompetenceOptionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CompetenceOptionsQuery, CompetenceOptionsQueryVariables>(CompetenceOptionsDocument, options);
        }
export type CompetenceOptionsQueryHookResult = ReturnType<typeof useCompetenceOptionsQuery>;
export type CompetenceOptionsLazyQueryHookResult = ReturnType<typeof useCompetenceOptionsLazyQuery>;
export type CompetenceOptionsSuspenseQueryHookResult = ReturnType<typeof useCompetenceOptionsSuspenseQuery>;
export type CompetenceOptionsQueryResult = Apollo.QueryResult<CompetenceOptionsQuery, CompetenceOptionsQueryVariables>;
export const CompetenceOptionsWithTopicsDocument = gql`
    query competenceOptionsWithTopics($filter: ListCompetenceFilterInput = {}, $limit: Float! = 100, $page: Float! = 0) {
  competences(filter: $filter, page: $page, limit: $limit) {
    totalCount
    data {
      id
      title
      grades
      programingLanguage
      topics {
        id
        title
      }
    }
  }
}
    `;

/**
 * __useCompetenceOptionsWithTopicsQuery__
 *
 * To run a query within a React component, call `useCompetenceOptionsWithTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompetenceOptionsWithTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompetenceOptionsWithTopicsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useCompetenceOptionsWithTopicsQuery(baseOptions?: Apollo.QueryHookOptions<CompetenceOptionsWithTopicsQuery, CompetenceOptionsWithTopicsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompetenceOptionsWithTopicsQuery, CompetenceOptionsWithTopicsQueryVariables>(CompetenceOptionsWithTopicsDocument, options);
      }
export function useCompetenceOptionsWithTopicsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompetenceOptionsWithTopicsQuery, CompetenceOptionsWithTopicsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompetenceOptionsWithTopicsQuery, CompetenceOptionsWithTopicsQueryVariables>(CompetenceOptionsWithTopicsDocument, options);
        }
export function useCompetenceOptionsWithTopicsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CompetenceOptionsWithTopicsQuery, CompetenceOptionsWithTopicsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CompetenceOptionsWithTopicsQuery, CompetenceOptionsWithTopicsQueryVariables>(CompetenceOptionsWithTopicsDocument, options);
        }
export type CompetenceOptionsWithTopicsQueryHookResult = ReturnType<typeof useCompetenceOptionsWithTopicsQuery>;
export type CompetenceOptionsWithTopicsLazyQueryHookResult = ReturnType<typeof useCompetenceOptionsWithTopicsLazyQuery>;
export type CompetenceOptionsWithTopicsSuspenseQueryHookResult = ReturnType<typeof useCompetenceOptionsWithTopicsSuspenseQuery>;
export type CompetenceOptionsWithTopicsQueryResult = Apollo.QueryResult<CompetenceOptionsWithTopicsQuery, CompetenceOptionsWithTopicsQueryVariables>;
export const CompetenceSpecializationsDocument = gql`
    query competenceSpecializations {
  competenceSpecializations
}
    `;

/**
 * __useCompetenceSpecializationsQuery__
 *
 * To run a query within a React component, call `useCompetenceSpecializationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompetenceSpecializationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompetenceSpecializationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompetenceSpecializationsQuery(baseOptions?: Apollo.QueryHookOptions<CompetenceSpecializationsQuery, CompetenceSpecializationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompetenceSpecializationsQuery, CompetenceSpecializationsQueryVariables>(CompetenceSpecializationsDocument, options);
      }
export function useCompetenceSpecializationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompetenceSpecializationsQuery, CompetenceSpecializationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompetenceSpecializationsQuery, CompetenceSpecializationsQueryVariables>(CompetenceSpecializationsDocument, options);
        }
export function useCompetenceSpecializationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CompetenceSpecializationsQuery, CompetenceSpecializationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CompetenceSpecializationsQuery, CompetenceSpecializationsQueryVariables>(CompetenceSpecializationsDocument, options);
        }
export type CompetenceSpecializationsQueryHookResult = ReturnType<typeof useCompetenceSpecializationsQuery>;
export type CompetenceSpecializationsLazyQueryHookResult = ReturnType<typeof useCompetenceSpecializationsLazyQuery>;
export type CompetenceSpecializationsSuspenseQueryHookResult = ReturnType<typeof useCompetenceSpecializationsSuspenseQuery>;
export type CompetenceSpecializationsQueryResult = Apollo.QueryResult<CompetenceSpecializationsQuery, CompetenceSpecializationsQueryVariables>;
export const CompetencesDocument = gql`
    query competences($filter: ListCompetenceFilterInput, $limit: Float!, $page: Float!) {
  competences(filter: $filter, page: $page, limit: $limit) {
    totalCount
    data {
      id
      title
      grades
      topics {
        id
      }
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useCompetencesQuery__
 *
 * To run a query within a React component, call `useCompetencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompetencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompetencesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useCompetencesQuery(baseOptions: Apollo.QueryHookOptions<CompetencesQuery, CompetencesQueryVariables> & ({ variables: CompetencesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompetencesQuery, CompetencesQueryVariables>(CompetencesDocument, options);
      }
export function useCompetencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompetencesQuery, CompetencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompetencesQuery, CompetencesQueryVariables>(CompetencesDocument, options);
        }
export function useCompetencesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CompetencesQuery, CompetencesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CompetencesQuery, CompetencesQueryVariables>(CompetencesDocument, options);
        }
export type CompetencesQueryHookResult = ReturnType<typeof useCompetencesQuery>;
export type CompetencesLazyQueryHookResult = ReturnType<typeof useCompetencesLazyQuery>;
export type CompetencesSuspenseQueryHookResult = ReturnType<typeof useCompetencesSuspenseQuery>;
export type CompetencesQueryResult = Apollo.QueryResult<CompetencesQuery, CompetencesQueryVariables>;
export const CopyCompetencyMapDocument = gql`
    mutation copyCompetencyMap($input: CopyCompetenceMapInputDto!) {
  copyCompetencyMap(input: $input) {
    id
  }
}
    `;
export type CopyCompetencyMapMutationFn = Apollo.MutationFunction<CopyCompetencyMapMutation, CopyCompetencyMapMutationVariables>;

/**
 * __useCopyCompetencyMapMutation__
 *
 * To run a mutation, you first call `useCopyCompetencyMapMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyCompetencyMapMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyCompetencyMapMutation, { data, loading, error }] = useCopyCompetencyMapMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopyCompetencyMapMutation(baseOptions?: Apollo.MutationHookOptions<CopyCompetencyMapMutation, CopyCompetencyMapMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopyCompetencyMapMutation, CopyCompetencyMapMutationVariables>(CopyCompetencyMapDocument, options);
      }
export type CopyCompetencyMapMutationHookResult = ReturnType<typeof useCopyCompetencyMapMutation>;
export type CopyCompetencyMapMutationResult = Apollo.MutationResult<CopyCompetencyMapMutation>;
export type CopyCompetencyMapMutationOptions = Apollo.BaseMutationOptions<CopyCompetencyMapMutation, CopyCompetencyMapMutationVariables>;
export const CreateOrUpdateCompetencyMapDocument = gql`
    mutation createOrUpdateCompetencyMap($input: CreateOrUpdateCompetencyMapInput!) {
  createOrUpdateCompetencyMap(input: $input) {
    id
    title
    specialization
    comment
    createdAt
    updatedAt
    grades
    useInBot
    isRepositoryCheck
    demoCode
    knowledgeBlocks {
      id
      title
      knowledgeBlockCompetences {
        id
        competence {
          id
          title
          topics {
            id
            title
          }
        }
      }
    }
  }
}
    `;
export type CreateOrUpdateCompetencyMapMutationFn = Apollo.MutationFunction<CreateOrUpdateCompetencyMapMutation, CreateOrUpdateCompetencyMapMutationVariables>;

/**
 * __useCreateOrUpdateCompetencyMapMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateCompetencyMapMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateCompetencyMapMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateCompetencyMapMutation, { data, loading, error }] = useCreateOrUpdateCompetencyMapMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrUpdateCompetencyMapMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateCompetencyMapMutation, CreateOrUpdateCompetencyMapMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateCompetencyMapMutation, CreateOrUpdateCompetencyMapMutationVariables>(CreateOrUpdateCompetencyMapDocument, options);
      }
export type CreateOrUpdateCompetencyMapMutationHookResult = ReturnType<typeof useCreateOrUpdateCompetencyMapMutation>;
export type CreateOrUpdateCompetencyMapMutationResult = Apollo.MutationResult<CreateOrUpdateCompetencyMapMutation>;
export type CreateOrUpdateCompetencyMapMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateCompetencyMapMutation, CreateOrUpdateCompetencyMapMutationVariables>;
export const DeleteCompetencyMapDocument = gql`
    mutation deleteCompetencyMap($competencyMapId: ID!) {
  deleteCompetencyMap(competencyMapId: $competencyMapId)
}
    `;
export type DeleteCompetencyMapMutationFn = Apollo.MutationFunction<DeleteCompetencyMapMutation, DeleteCompetencyMapMutationVariables>;

/**
 * __useDeleteCompetencyMapMutation__
 *
 * To run a mutation, you first call `useDeleteCompetencyMapMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompetencyMapMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompetencyMapMutation, { data, loading, error }] = useDeleteCompetencyMapMutation({
 *   variables: {
 *      competencyMapId: // value for 'competencyMapId'
 *   },
 * });
 */
export function useDeleteCompetencyMapMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCompetencyMapMutation, DeleteCompetencyMapMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCompetencyMapMutation, DeleteCompetencyMapMutationVariables>(DeleteCompetencyMapDocument, options);
      }
export type DeleteCompetencyMapMutationHookResult = ReturnType<typeof useDeleteCompetencyMapMutation>;
export type DeleteCompetencyMapMutationResult = Apollo.MutationResult<DeleteCompetencyMapMutation>;
export type DeleteCompetencyMapMutationOptions = Apollo.BaseMutationOptions<DeleteCompetencyMapMutation, DeleteCompetencyMapMutationVariables>;
export const CompetencyMapDocument = gql`
    query competencyMap($competencyMapId: ID!) {
  competencyMap(competencyMapId: $competencyMapId) {
    id
    title
    specialization
    comment
    createdAt
    updatedAt
    grades
    useInBot
    demoCode
    isRepositoryCheck
    originalCompetencyMap {
      id
      title
    }
    knowledgeBlocks {
      id
      title
      knowledgeBlockCompetences {
        id
        competence {
          id
          title
          topics {
            id
            title
            questions {
              id
              confirmation
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useCompetencyMapQuery__
 *
 * To run a query within a React component, call `useCompetencyMapQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompetencyMapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompetencyMapQuery({
 *   variables: {
 *      competencyMapId: // value for 'competencyMapId'
 *   },
 * });
 */
export function useCompetencyMapQuery(baseOptions: Apollo.QueryHookOptions<CompetencyMapQuery, CompetencyMapQueryVariables> & ({ variables: CompetencyMapQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompetencyMapQuery, CompetencyMapQueryVariables>(CompetencyMapDocument, options);
      }
export function useCompetencyMapLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompetencyMapQuery, CompetencyMapQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompetencyMapQuery, CompetencyMapQueryVariables>(CompetencyMapDocument, options);
        }
export function useCompetencyMapSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CompetencyMapQuery, CompetencyMapQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CompetencyMapQuery, CompetencyMapQueryVariables>(CompetencyMapDocument, options);
        }
export type CompetencyMapQueryHookResult = ReturnType<typeof useCompetencyMapQuery>;
export type CompetencyMapLazyQueryHookResult = ReturnType<typeof useCompetencyMapLazyQuery>;
export type CompetencyMapSuspenseQueryHookResult = ReturnType<typeof useCompetencyMapSuspenseQuery>;
export type CompetencyMapQueryResult = Apollo.QueryResult<CompetencyMapQuery, CompetencyMapQueryVariables>;
export const CompetencyMapOptionsDocument = gql`
    query competencyMapOptions($filter: ListCompetencyMapFilterInput = {}, $limit: Float! = 1000, $page: Float! = 0) {
  competencyMaps(limit: $limit, page: $page, filter: $filter) {
    data {
      id
      title
      grades
      comment
    }
    totalCount
  }
}
    `;

/**
 * __useCompetencyMapOptionsQuery__
 *
 * To run a query within a React component, call `useCompetencyMapOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompetencyMapOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompetencyMapOptionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useCompetencyMapOptionsQuery(baseOptions?: Apollo.QueryHookOptions<CompetencyMapOptionsQuery, CompetencyMapOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompetencyMapOptionsQuery, CompetencyMapOptionsQueryVariables>(CompetencyMapOptionsDocument, options);
      }
export function useCompetencyMapOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompetencyMapOptionsQuery, CompetencyMapOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompetencyMapOptionsQuery, CompetencyMapOptionsQueryVariables>(CompetencyMapOptionsDocument, options);
        }
export function useCompetencyMapOptionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CompetencyMapOptionsQuery, CompetencyMapOptionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CompetencyMapOptionsQuery, CompetencyMapOptionsQueryVariables>(CompetencyMapOptionsDocument, options);
        }
export type CompetencyMapOptionsQueryHookResult = ReturnType<typeof useCompetencyMapOptionsQuery>;
export type CompetencyMapOptionsLazyQueryHookResult = ReturnType<typeof useCompetencyMapOptionsLazyQuery>;
export type CompetencyMapOptionsSuspenseQueryHookResult = ReturnType<typeof useCompetencyMapOptionsSuspenseQuery>;
export type CompetencyMapOptionsQueryResult = Apollo.QueryResult<CompetencyMapOptionsQuery, CompetencyMapOptionsQueryVariables>;
export const CompetencyMapsDocument = gql`
    query competencyMaps($filter: ListCompetencyMapFilterInput, $limit: Float!, $page: Float!) {
  competencyMaps(filter: $filter, page: $page, limit: $limit) {
    totalCount
    data {
      id
      title
      grades
      blocksCount
      trialsCount
      specialization
      createdAt
      updatedAt
      knowledgeBlocks {
        id
        knowledgeBlockCompetences {
          id
          competence {
            id
            topics {
              id
              questions {
                id
                confirmation
              }
            }
          }
        }
      }
      isSample
    }
  }
}
    `;

/**
 * __useCompetencyMapsQuery__
 *
 * To run a query within a React component, call `useCompetencyMapsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompetencyMapsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompetencyMapsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useCompetencyMapsQuery(baseOptions: Apollo.QueryHookOptions<CompetencyMapsQuery, CompetencyMapsQueryVariables> & ({ variables: CompetencyMapsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompetencyMapsQuery, CompetencyMapsQueryVariables>(CompetencyMapsDocument, options);
      }
export function useCompetencyMapsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompetencyMapsQuery, CompetencyMapsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompetencyMapsQuery, CompetencyMapsQueryVariables>(CompetencyMapsDocument, options);
        }
export function useCompetencyMapsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CompetencyMapsQuery, CompetencyMapsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CompetencyMapsQuery, CompetencyMapsQueryVariables>(CompetencyMapsDocument, options);
        }
export type CompetencyMapsQueryHookResult = ReturnType<typeof useCompetencyMapsQuery>;
export type CompetencyMapsLazyQueryHookResult = ReturnType<typeof useCompetencyMapsLazyQuery>;
export type CompetencyMapsSuspenseQueryHookResult = ReturnType<typeof useCompetencyMapsSuspenseQuery>;
export type CompetencyMapsQueryResult = Apollo.QueryResult<CompetencyMapsQuery, CompetencyMapsQueryVariables>;
export const ConfirmOrRejectQuestionDocument = gql`
    mutation confirmOrRejectQuestion($questionId: ID!) {
  confirmOrRejectQuestion(questionId: $questionId) {
    id
    time
    text
    criterion
    confirmation
    grade
    recommendedTime
    type
    subtype
  }
}
    `;
export type ConfirmOrRejectQuestionMutationFn = Apollo.MutationFunction<ConfirmOrRejectQuestionMutation, ConfirmOrRejectQuestionMutationVariables>;

/**
 * __useConfirmOrRejectQuestionMutation__
 *
 * To run a mutation, you first call `useConfirmOrRejectQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmOrRejectQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmOrRejectQuestionMutation, { data, loading, error }] = useConfirmOrRejectQuestionMutation({
 *   variables: {
 *      questionId: // value for 'questionId'
 *   },
 * });
 */
export function useConfirmOrRejectQuestionMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmOrRejectQuestionMutation, ConfirmOrRejectQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmOrRejectQuestionMutation, ConfirmOrRejectQuestionMutationVariables>(ConfirmOrRejectQuestionDocument, options);
      }
export type ConfirmOrRejectQuestionMutationHookResult = ReturnType<typeof useConfirmOrRejectQuestionMutation>;
export type ConfirmOrRejectQuestionMutationResult = Apollo.MutationResult<ConfirmOrRejectQuestionMutation>;
export type ConfirmOrRejectQuestionMutationOptions = Apollo.BaseMutationOptions<ConfirmOrRejectQuestionMutation, ConfirmOrRejectQuestionMutationVariables>;
export const CreateOrUpdateQuestionDocument = gql`
    mutation createOrUpdateQuestion($input: CreateOrUpdateQuestionInput!) {
  createOrUpdateQuestion(input: $input) {
    id
  }
}
    `;
export type CreateOrUpdateQuestionMutationFn = Apollo.MutationFunction<CreateOrUpdateQuestionMutation, CreateOrUpdateQuestionMutationVariables>;

/**
 * __useCreateOrUpdateQuestionMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateQuestionMutation, { data, loading, error }] = useCreateOrUpdateQuestionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrUpdateQuestionMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateQuestionMutation, CreateOrUpdateQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateQuestionMutation, CreateOrUpdateQuestionMutationVariables>(CreateOrUpdateQuestionDocument, options);
      }
export type CreateOrUpdateQuestionMutationHookResult = ReturnType<typeof useCreateOrUpdateQuestionMutation>;
export type CreateOrUpdateQuestionMutationResult = Apollo.MutationResult<CreateOrUpdateQuestionMutation>;
export type CreateOrUpdateQuestionMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateQuestionMutation, CreateOrUpdateQuestionMutationVariables>;
export const DeleteQuestionDocument = gql`
    mutation deleteQuestion($questionId: ID!) {
  deleteQuestion(questionId: $questionId)
}
    `;
export type DeleteQuestionMutationFn = Apollo.MutationFunction<DeleteQuestionMutation, DeleteQuestionMutationVariables>;

/**
 * __useDeleteQuestionMutation__
 *
 * To run a mutation, you first call `useDeleteQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQuestionMutation, { data, loading, error }] = useDeleteQuestionMutation({
 *   variables: {
 *      questionId: // value for 'questionId'
 *   },
 * });
 */
export function useDeleteQuestionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteQuestionMutation, DeleteQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteQuestionMutation, DeleteQuestionMutationVariables>(DeleteQuestionDocument, options);
      }
export type DeleteQuestionMutationHookResult = ReturnType<typeof useDeleteQuestionMutation>;
export type DeleteQuestionMutationResult = Apollo.MutationResult<DeleteQuestionMutation>;
export type DeleteQuestionMutationOptions = Apollo.BaseMutationOptions<DeleteQuestionMutation, DeleteQuestionMutationVariables>;
export const GenerateQuestionsDocument = gql`
    mutation generateQuestions($input: InitGenerateQuestionsInput!) {
  initGenerateQuestions(input: $input)
}
    `;
export type GenerateQuestionsMutationFn = Apollo.MutationFunction<GenerateQuestionsMutation, GenerateQuestionsMutationVariables>;

/**
 * __useGenerateQuestionsMutation__
 *
 * To run a mutation, you first call `useGenerateQuestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateQuestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateQuestionsMutation, { data, loading, error }] = useGenerateQuestionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateQuestionsMutation(baseOptions?: Apollo.MutationHookOptions<GenerateQuestionsMutation, GenerateQuestionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateQuestionsMutation, GenerateQuestionsMutationVariables>(GenerateQuestionsDocument, options);
      }
export type GenerateQuestionsMutationHookResult = ReturnType<typeof useGenerateQuestionsMutation>;
export type GenerateQuestionsMutationResult = Apollo.MutationResult<GenerateQuestionsMutation>;
export type GenerateQuestionsMutationOptions = Apollo.BaseMutationOptions<GenerateQuestionsMutation, GenerateQuestionsMutationVariables>;
export const QuestionSubtypesDocument = gql`
    query questionSubtypes {
  questionSubtypes
}
    `;

/**
 * __useQuestionSubtypesQuery__
 *
 * To run a query within a React component, call `useQuestionSubtypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionSubtypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionSubtypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useQuestionSubtypesQuery(baseOptions?: Apollo.QueryHookOptions<QuestionSubtypesQuery, QuestionSubtypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionSubtypesQuery, QuestionSubtypesQueryVariables>(QuestionSubtypesDocument, options);
      }
export function useQuestionSubtypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionSubtypesQuery, QuestionSubtypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionSubtypesQuery, QuestionSubtypesQueryVariables>(QuestionSubtypesDocument, options);
        }
export function useQuestionSubtypesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<QuestionSubtypesQuery, QuestionSubtypesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<QuestionSubtypesQuery, QuestionSubtypesQueryVariables>(QuestionSubtypesDocument, options);
        }
export type QuestionSubtypesQueryHookResult = ReturnType<typeof useQuestionSubtypesQuery>;
export type QuestionSubtypesLazyQueryHookResult = ReturnType<typeof useQuestionSubtypesLazyQuery>;
export type QuestionSubtypesSuspenseQueryHookResult = ReturnType<typeof useQuestionSubtypesSuspenseQuery>;
export type QuestionSubtypesQueryResult = Apollo.QueryResult<QuestionSubtypesQuery, QuestionSubtypesQueryVariables>;
export const CreateOrUpdateTrialDocument = gql`
    mutation createOrUpdateTrial($input: CreateOrUpdateTrialInput!) {
  createOrUpdateTrial(input: $input) {
    id
    comment
    status
    numericId
    companyCandidate {
      id
      fullName
      candidateUsername {
        id
        telegramUsername
        candidate {
          id
          phoneNumber
        }
      }
      email
      grade
    }
    competencyMap {
      id
      title
      comment
      grades
    }
    createdAt
    trialStatusHistory {
      id
      newStatus
      createdAt
    }
  }
}
    `;
export type CreateOrUpdateTrialMutationFn = Apollo.MutationFunction<CreateOrUpdateTrialMutation, CreateOrUpdateTrialMutationVariables>;

/**
 * __useCreateOrUpdateTrialMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateTrialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateTrialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateTrialMutation, { data, loading, error }] = useCreateOrUpdateTrialMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrUpdateTrialMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateTrialMutation, CreateOrUpdateTrialMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateTrialMutation, CreateOrUpdateTrialMutationVariables>(CreateOrUpdateTrialDocument, options);
      }
export type CreateOrUpdateTrialMutationHookResult = ReturnType<typeof useCreateOrUpdateTrialMutation>;
export type CreateOrUpdateTrialMutationResult = Apollo.MutationResult<CreateOrUpdateTrialMutation>;
export type CreateOrUpdateTrialMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateTrialMutation, CreateOrUpdateTrialMutationVariables>;
export const SetTrialStatusDocument = gql`
    mutation setTrialStatus($input: SetTrialStatusInput!) {
  setTrialStatus(input: $input) {
    id
    status
  }
}
    `;
export type SetTrialStatusMutationFn = Apollo.MutationFunction<SetTrialStatusMutation, SetTrialStatusMutationVariables>;

/**
 * __useSetTrialStatusMutation__
 *
 * To run a mutation, you first call `useSetTrialStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTrialStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTrialStatusMutation, { data, loading, error }] = useSetTrialStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetTrialStatusMutation(baseOptions?: Apollo.MutationHookOptions<SetTrialStatusMutation, SetTrialStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetTrialStatusMutation, SetTrialStatusMutationVariables>(SetTrialStatusDocument, options);
      }
export type SetTrialStatusMutationHookResult = ReturnType<typeof useSetTrialStatusMutation>;
export type SetTrialStatusMutationResult = Apollo.MutationResult<SetTrialStatusMutation>;
export type SetTrialStatusMutationOptions = Apollo.BaseMutationOptions<SetTrialStatusMutation, SetTrialStatusMutationVariables>;
export const TrialHeaderDocument = gql`
    query trialHeader($trialId: ID!) {
  trial(trialId: $trialId) {
    id
    numericId
    status
    companyCandidate {
      id
      fullName
    }
    completedAt
  }
}
    `;

/**
 * __useTrialHeaderQuery__
 *
 * To run a query within a React component, call `useTrialHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrialHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrialHeaderQuery({
 *   variables: {
 *      trialId: // value for 'trialId'
 *   },
 * });
 */
export function useTrialHeaderQuery(baseOptions: Apollo.QueryHookOptions<TrialHeaderQuery, TrialHeaderQueryVariables> & ({ variables: TrialHeaderQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TrialHeaderQuery, TrialHeaderQueryVariables>(TrialHeaderDocument, options);
      }
export function useTrialHeaderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TrialHeaderQuery, TrialHeaderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TrialHeaderQuery, TrialHeaderQueryVariables>(TrialHeaderDocument, options);
        }
export function useTrialHeaderSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TrialHeaderQuery, TrialHeaderQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TrialHeaderQuery, TrialHeaderQueryVariables>(TrialHeaderDocument, options);
        }
export type TrialHeaderQueryHookResult = ReturnType<typeof useTrialHeaderQuery>;
export type TrialHeaderLazyQueryHookResult = ReturnType<typeof useTrialHeaderLazyQuery>;
export type TrialHeaderSuspenseQueryHookResult = ReturnType<typeof useTrialHeaderSuspenseQuery>;
export type TrialHeaderQueryResult = Apollo.QueryResult<TrialHeaderQuery, TrialHeaderQueryVariables>;
export const TrialInfoDocument = gql`
    query trialInfo($trialId: ID!) {
  trial(trialId: $trialId) {
    id
    comment
    companyCandidate {
      id
      fullName
      candidateUsername {
        id
        telegramUsername
        candidate {
          id
          phoneNumber
        }
      }
      email
      grade
    }
    competencyMap {
      id
      title
      comment
      grades
    }
    createdAt
    completedAt
    trialStatusHistory {
      id
      newStatus
      createdAt
    }
  }
}
    `;

/**
 * __useTrialInfoQuery__
 *
 * To run a query within a React component, call `useTrialInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrialInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrialInfoQuery({
 *   variables: {
 *      trialId: // value for 'trialId'
 *   },
 * });
 */
export function useTrialInfoQuery(baseOptions: Apollo.QueryHookOptions<TrialInfoQuery, TrialInfoQueryVariables> & ({ variables: TrialInfoQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TrialInfoQuery, TrialInfoQueryVariables>(TrialInfoDocument, options);
      }
export function useTrialInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TrialInfoQuery, TrialInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TrialInfoQuery, TrialInfoQueryVariables>(TrialInfoDocument, options);
        }
export function useTrialInfoSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TrialInfoQuery, TrialInfoQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TrialInfoQuery, TrialInfoQueryVariables>(TrialInfoDocument, options);
        }
export type TrialInfoQueryHookResult = ReturnType<typeof useTrialInfoQuery>;
export type TrialInfoLazyQueryHookResult = ReturnType<typeof useTrialInfoLazyQuery>;
export type TrialInfoSuspenseQueryHookResult = ReturnType<typeof useTrialInfoSuspenseQuery>;
export type TrialInfoQueryResult = Apollo.QueryResult<TrialInfoQuery, TrialInfoQueryVariables>;
export const TrialResultDocument = gql`
    query trialResult($trialId: ID!) {
  trial(trialId: $trialId) {
    id
    candidateScore
    trialRepositoryChecks {
      id
      algorithmsScore
      error
      facts {
        first_release_date
        main_language
        developers_count
        forks_count
        languages
        repo_url
        stars_count
      }
      maintainabilityScore
      readabilityScore
      repoUrl
      totalScore
    }
    competencyMap {
      id
      knowledgeBlocks {
        id
        title
        knowledgeBlockCompetences {
          id
          competence {
            id
            title
            topics {
              id
              title
              trialAnswer(trialId: $trialId) {
                id
                score
                time
                answer
                question {
                  id
                  text
                }
                criterionAssessments {
                  criteria
                  decision
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useTrialResultQuery__
 *
 * To run a query within a React component, call `useTrialResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrialResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrialResultQuery({
 *   variables: {
 *      trialId: // value for 'trialId'
 *   },
 * });
 */
export function useTrialResultQuery(baseOptions: Apollo.QueryHookOptions<TrialResultQuery, TrialResultQueryVariables> & ({ variables: TrialResultQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TrialResultQuery, TrialResultQueryVariables>(TrialResultDocument, options);
      }
export function useTrialResultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TrialResultQuery, TrialResultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TrialResultQuery, TrialResultQueryVariables>(TrialResultDocument, options);
        }
export function useTrialResultSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TrialResultQuery, TrialResultQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TrialResultQuery, TrialResultQueryVariables>(TrialResultDocument, options);
        }
export type TrialResultQueryHookResult = ReturnType<typeof useTrialResultQuery>;
export type TrialResultLazyQueryHookResult = ReturnType<typeof useTrialResultLazyQuery>;
export type TrialResultSuspenseQueryHookResult = ReturnType<typeof useTrialResultSuspenseQuery>;
export type TrialResultQueryResult = Apollo.QueryResult<TrialResultQuery, TrialResultQueryVariables>;
export const TrialsDocument = gql`
    query trials($filter: ListTrialFilterInput, $limit: Float!, $order: PlatformTrialListOrderInput!, $page: Float!) {
  trials(filter: $filter, limit: $limit, page: $page, order: $order) {
    totalCount
    data {
      id
      numericId
      competencyMap {
        id
        title
      }
      companyCandidate {
        id
        fullName
        grade
      }
      status
      createdAt
      completedAt
    }
  }
}
    `;

/**
 * __useTrialsQuery__
 *
 * To run a query within a React component, call `useTrialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrialsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useTrialsQuery(baseOptions: Apollo.QueryHookOptions<TrialsQuery, TrialsQueryVariables> & ({ variables: TrialsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TrialsQuery, TrialsQueryVariables>(TrialsDocument, options);
      }
export function useTrialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TrialsQuery, TrialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TrialsQuery, TrialsQueryVariables>(TrialsDocument, options);
        }
export function useTrialsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TrialsQuery, TrialsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TrialsQuery, TrialsQueryVariables>(TrialsDocument, options);
        }
export type TrialsQueryHookResult = ReturnType<typeof useTrialsQuery>;
export type TrialsLazyQueryHookResult = ReturnType<typeof useTrialsLazyQuery>;
export type TrialsSuspenseQueryHookResult = ReturnType<typeof useTrialsSuspenseQuery>;
export type TrialsQueryResult = Apollo.QueryResult<TrialsQuery, TrialsQueryVariables>;
export const CreateOrUpdateTopicDocument = gql`
    mutation createOrUpdateTopic($input: CreateOrUpdateTopicInput!) {
  createOrUpdateTopic(input: $input) {
    id
    title
    competence {
      id
      title
      programingLanguage
    }
    grades
    createdAt
    updatedAt
    questions {
      id
      confirmation
      text
      criterion
      time
      microCriterion
      type
      subtype
    }
  }
}
    `;
export type CreateOrUpdateTopicMutationFn = Apollo.MutationFunction<CreateOrUpdateTopicMutation, CreateOrUpdateTopicMutationVariables>;

/**
 * __useCreateOrUpdateTopicMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateTopicMutation, { data, loading, error }] = useCreateOrUpdateTopicMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrUpdateTopicMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateTopicMutation, CreateOrUpdateTopicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateTopicMutation, CreateOrUpdateTopicMutationVariables>(CreateOrUpdateTopicDocument, options);
      }
export type CreateOrUpdateTopicMutationHookResult = ReturnType<typeof useCreateOrUpdateTopicMutation>;
export type CreateOrUpdateTopicMutationResult = Apollo.MutationResult<CreateOrUpdateTopicMutation>;
export type CreateOrUpdateTopicMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateTopicMutation, CreateOrUpdateTopicMutationVariables>;
export const DeleteTopicDocument = gql`
    mutation deleteTopic($topicId: ID!) {
  deleteTopic(topicId: $topicId)
}
    `;
export type DeleteTopicMutationFn = Apollo.MutationFunction<DeleteTopicMutation, DeleteTopicMutationVariables>;

/**
 * __useDeleteTopicMutation__
 *
 * To run a mutation, you first call `useDeleteTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTopicMutation, { data, loading, error }] = useDeleteTopicMutation({
 *   variables: {
 *      topicId: // value for 'topicId'
 *   },
 * });
 */
export function useDeleteTopicMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTopicMutation, DeleteTopicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTopicMutation, DeleteTopicMutationVariables>(DeleteTopicDocument, options);
      }
export type DeleteTopicMutationHookResult = ReturnType<typeof useDeleteTopicMutation>;
export type DeleteTopicMutationResult = Apollo.MutationResult<DeleteTopicMutation>;
export type DeleteTopicMutationOptions = Apollo.BaseMutationOptions<DeleteTopicMutation, DeleteTopicMutationVariables>;
export const TopicDocument = gql`
    query topic($topicId: ID!) {
  topic(topicId: $topicId) {
    id
    title
    competence {
      id
      title
      programingLanguage
    }
    grades
    createdAt
    updatedAt
    questions {
      id
      confirmation
      text
      criterion
      time
      grade
      recommendedTime
      microCriterion
      type
      subtype
      language
    }
  }
}
    `;

/**
 * __useTopicQuery__
 *
 * To run a query within a React component, call `useTopicQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopicQuery({
 *   variables: {
 *      topicId: // value for 'topicId'
 *   },
 * });
 */
export function useTopicQuery(baseOptions: Apollo.QueryHookOptions<TopicQuery, TopicQueryVariables> & ({ variables: TopicQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TopicQuery, TopicQueryVariables>(TopicDocument, options);
      }
export function useTopicLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopicQuery, TopicQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TopicQuery, TopicQueryVariables>(TopicDocument, options);
        }
export function useTopicSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TopicQuery, TopicQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TopicQuery, TopicQueryVariables>(TopicDocument, options);
        }
export type TopicQueryHookResult = ReturnType<typeof useTopicQuery>;
export type TopicLazyQueryHookResult = ReturnType<typeof useTopicLazyQuery>;
export type TopicSuspenseQueryHookResult = ReturnType<typeof useTopicSuspenseQuery>;
export type TopicQueryResult = Apollo.QueryResult<TopicQuery, TopicQueryVariables>;
export const TopicsDocument = gql`
    query topics($filter: ListTopicFilterInput!, $limit: Float!, $page: Float!) {
  topics(filter: $filter, page: $page, limit: $limit) {
    totalCount
    data {
      id
      title
      grades
      confirmedQuestionsCount
      competence {
        id
        title
      }
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useTopicsQuery__
 *
 * To run a query within a React component, call `useTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopicsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useTopicsQuery(baseOptions: Apollo.QueryHookOptions<TopicsQuery, TopicsQueryVariables> & ({ variables: TopicsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TopicsQuery, TopicsQueryVariables>(TopicsDocument, options);
      }
export function useTopicsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopicsQuery, TopicsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TopicsQuery, TopicsQueryVariables>(TopicsDocument, options);
        }
export function useTopicsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TopicsQuery, TopicsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TopicsQuery, TopicsQueryVariables>(TopicsDocument, options);
        }
export type TopicsQueryHookResult = ReturnType<typeof useTopicsQuery>;
export type TopicsLazyQueryHookResult = ReturnType<typeof useTopicsLazyQuery>;
export type TopicsSuspenseQueryHookResult = ReturnType<typeof useTopicsSuspenseQuery>;
export type TopicsQueryResult = Apollo.QueryResult<TopicsQuery, TopicsQueryVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($input: UpdateUserDataInput!) {
  updateUser(input: $input) {
    id
    firstName
    lastName
    middleName
    userEmail {
      id
      email
    }
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UserCompaniesDocument = gql`
    query userCompanies {
  userCompanies {
    id
    shortName
    currentUserPosition
  }
}
    `;

/**
 * __useUserCompaniesQuery__
 *
 * To run a query within a React component, call `useUserCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<UserCompaniesQuery, UserCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserCompaniesQuery, UserCompaniesQueryVariables>(UserCompaniesDocument, options);
      }
export function useUserCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserCompaniesQuery, UserCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserCompaniesQuery, UserCompaniesQueryVariables>(UserCompaniesDocument, options);
        }
export function useUserCompaniesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UserCompaniesQuery, UserCompaniesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserCompaniesQuery, UserCompaniesQueryVariables>(UserCompaniesDocument, options);
        }
export type UserCompaniesQueryHookResult = ReturnType<typeof useUserCompaniesQuery>;
export type UserCompaniesLazyQueryHookResult = ReturnType<typeof useUserCompaniesLazyQuery>;
export type UserCompaniesSuspenseQueryHookResult = ReturnType<typeof useUserCompaniesSuspenseQuery>;
export type UserCompaniesQueryResult = Apollo.QueryResult<UserCompaniesQuery, UserCompaniesQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    id
    firstName
    lastName
    middleName
    userEmail {
      id
      email
    }
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export function useMeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeSuspenseQueryHookResult = ReturnType<typeof useMeSuspenseQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;