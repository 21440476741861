import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import React from 'react';

const LabelBox = ({ text }: { text: string }) => (
  <Box sx={{
    boxShadow: '0px 0px 7.3px 0px rgba(164, 184, 253, 0.24)',
    p: 1,
    borderRadius: 1,
  }}
  >
    <Typography variant="body14rg" sx={{ color: 'primary.main' }}>
      {text}
    </Typography>
  </Box>
);

export default LabelBox;
